<template>
    <v-card elevation="16" class="wgd__login-card">
        <v-row>
            <v-col cols="12"><h3>Anmelden:</h3></v-col>
            <v-col cols="12" md="6">
                <v-text-field
                prepend-icon="mdi-account-circle"
                placeholder="Username"
                outlined
                single-line
                v-model="username"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                prepend-icon="mdi-lock"
                placeholder="Password"
                outlined
                single-line
                type="password"
                v-model="password"
                ></v-text-field>
            </v-col>
            <v-col offset="9">
                <v-btn
                outlined
                color="green"
                @click="login"
                >
                Login
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    data(){
        return {
            username: '',
            password: ''
        }
    },
    methods: {
        login: async function(){
            await this.$store.dispatch('login', {mail: this.username, password: this.password})
            if(this.$store.getters.isLoggedIn){
                this.$store.commit('showDefaultSnackbar', {
                    text: 'Erfolgreich angemeldet!',
                    success: true
                })
                setTimeout(()=>{
                    this.$router.push({name: 'HomeDashboardView'})
                }, 1000)
                
            }else{
                this.$store.commit('showDefaultSnackbar', {
                    text: 'Falsche Anmeldedaten!',
                    success: false
                })
            }
        }
    }
    
}
</script>

<style scoped>
*{
    padding: 1rem;
}

.wgd__login-card{
    margin: 1rem 1rem 0rem 1rem;
}
</style>