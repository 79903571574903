<template>
    <main>
        <AddButtonCard
        title="Bibliothek"
        :entity="entity"
        titleToPass="Eintrag hinzufügen"
        :viewName="viewName"
        >
            <v-simple-table slot="component">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Datenreferenzquelle
                            </th>
                            <th class="text-left">
                                Ansicht
                            </th>
                            <th class="text-left">
                                Bearbeiten
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="entry in library"
                        :key="entry.id"
                        >
                            <td>{{ entry.name }}</td>
                            <td>
                                <FormShowButton :viewName="viewName" :params="{_id: entry._id}"/>
                            </td>
                            <td>
                                <FormEditButton :viewName="viewName" :params="{_id: entry._id}"/>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </AddButtonCard>
    </main>
</template>

<script>
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
import AddButtonCard from '@/components/cards/AddButtonCard.vue'
export default {
    components:{
        FormEditButton,
        FormShowButton,
        AddButtonCard
    },
    data(){
        return {
            entity: this.$store.getters.getEntities.DATAREF,
            viewName: 'LibraryView',
            library: [
                {
                    name: "ISO 22301",
                    id: 4567
                }
            ]
        }
    },
    beforeMount(){
        this.$store.dispatch('getAll', this.entity)
            .then((datarefs)=>{
                this.library = datarefs.data
            })
    }

}
</script>

<style>

</style>