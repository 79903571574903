<template>
    <main>
        <v-form>
              <v-container>
                  <v-row class="wgd__questionform-bib--bg">
                      <h6 class="ma-4">Entnommen aus der Bibliothek:</h6>
                      <v-col cols="12">
                          <v-textarea
                          label="Zusätzliche Erläuterungen"
                          outlined
                          v-model="localQuestion.info"
                          :disabled="disabled"
                          >
                          </v-textarea>
                      </v-col>
                  </v-row>
                  <v-row>
                      <h6 class="ma-4">Während des Healthchecks zu ergänzen:</h6>
                      <v-col cols="12">
                          <h6 class="text-center">Bewertung
                              <v-icon color="green" v-if="localQuestion.answered">
                              mdi-check-decagram
                              </v-icon>
                              <v-icon color="orange" v-else>
                              mdi-exclamation-thick
                              </v-icon>
                          </h6>
                      </v-col>
                      <v-col cols="12">
                          <v-checkbox
                          class="ma-6"
                          v-model="localQuestion.answered"
                          label="Bewertet"
                          @change="localQuestion.aggregation = 0"
                          :disabled="disabled"
                      ></v-checkbox>
                      </v-col>
                      <v-col cols="12">
                          <v-slider
                          v-model="localQuestion.aggregation"
                          label="Bewertung"
                          :thumb-color="getColor"
                          :track-color="getColor"
                          :color="getColor"
                          thumb-label="always"
                          min="0"
                          max="10"
                          @start="()=>{
                              localQuestion.answered = true
                              localQuestion.notApplicable = false
                              }"
                          :readonly="disabled"
                          ></v-slider>
                      </v-col>
                      <v-col cols="3">
                          <v-checkbox
                              class="ma-6"
                              v-model="localQuestion.notApplicable"
                              label="Nicht zutreffend"
                              :append-icon="localQuestion.notApplicable ? 'mdi-arrow-right-bold-hexagon-outline': ''"
                              @change="(bool)=>{
                                  localQuestion.aggregation = 0
                                  localQuestion.answered = bool
                              }"
                              :disabled="disabled"
                          ></v-checkbox>
                      </v-col>
                      <v-col cols="9" v-if="localQuestion.notApplicable">
                          <v-textarea
                              class="ma-6"
                              v-model="localQuestion.notApplicableExp"
                              label="Begründung"
                              outlined
                              :disabled="disabled"
                          ></v-textarea>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12">
                          <h6 class="text-center">Kundeninformationen</h6>
                      </v-col>
                      <v-col cols="4">
                          <v-textarea
                          label="Dokumente"
                          outlined
                          v-model="localQuestion.docs"
                          :disabled="disabled"
                          >
                          </v-textarea>
                      </v-col>
                      <v-col cols="8">
                          <v-textarea
                          label="Kommentare des Kunden"
                          outlined
                          v-model="localQuestion.customerComment"
                          :disabled="disabled"
                          >
                          </v-textarea>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="4">
                          Interviewpartner
                      </v-col>
                      <v-col cols="4">
                          <v-text-field
                          label="Name"
                          outlined
                          v-model="localQuestion.interviewPartnerName"
                          :disabled="disabled"
                          >
                          </v-text-field>
                      </v-col>
                      <v-col cols="4">
                          <v-text-field
                          label="Email"
                          outlined
                          v-model="localQuestion.interviewPartnerMail"
                          :disabled="disabled"
                          >
                          </v-text-field>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12">
                          <h6 class="text-center">Handlungsempfehlungen der WG-DATA</h6>
                      </v-col>
                      <v-col offset="2" cols="8">
                          <v-textarea
                          label="Handlungsempfehlungen"
                          outlined
                          v-model="localQuestion.wgdataComment"
                          :disabled="disabled"
                          >
                          </v-textarea>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12" align="center" justify="center">
                          <FormSaveButton
                          verbose
                          :objectToSave="localQuestion"
                          :entity="this.$store.getters.getEntities.QUEST"
                          :newObject="false"
                          @savedSuccess="updateQuestion"
                          goTo=""
                          v-if="!disabled"
                          />
                      </v-col>
                  </v-row>
              </v-container>
          </v-form>
    </main>
  </template>
  
  <script>
  import FormSaveButton from '@/components/buttons/FormSaveButton.vue'
  export default {
      components: {
          FormSaveButton,
      },
      props: {
          question: {type: Object, required: true}
      },
      data(){
          return {
              localQuestion: JSON.parse(JSON.stringify(this.question)),
              disabled: this.$router.currentRoute.matched.some(route => route.meta.disabled),
          }
      },
      methods: {
          updateQuestion: function(question){
              this.$store.dispatch('get', {endpoint: this.$store.getters.getEntities.QUEST, _id: question._id})
                  .then(quest => {
                      this.localQuestion = quest.data
                      console.log(this.localQuestion.wgdataComment)
                      this.$emit('updateQuestion', quest.data)
                  })
                  .catch(err => {
                      console.log(err)
                  })
          }
      },
      watch: {
          question(newQuestion){
              this.localQuestion = JSON.parse(JSON.stringify(newQuestion))
          },
      },
      computed: {
          getColor(){
              switch(this.localQuestion.aggregation){
                  case -1: return '#ecf0f1'
                  case 1:
                  case 2:
                  case 3: return '#e74c3c'
                  case 4:
                  case 5:
                  case 6:
                  case 7: return '#f1c40f'
                  case 8:
                  case 9:
                  case 10: return '#2ecc71'
                  default: return '#bdc3c7'
              }
  
          }
      },
      
  }
  </script>
  
  <style scoped>
      .wgd__questionform-bib--bg{
          background: #ecf0f1;
      }
  </style>