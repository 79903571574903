<template>
  <v-stepper v-model="e1">
    <h4 class="text-center mb-9">
      <v-icon v-if="hc.version.slice(-1) === '0'" color="green" large>
        <!-- Final versions, which could be edited 
                TODO: Each version x.0 is final, not only 1.0
          -->
        mdi-star-circle
      </v-icon>
      {{ customer.name }} Healthcheck: {{ hc.name }}
      <v-btn class="mx-2" small icon @click="showMeta = !showMeta">
        <v-icon color="grey" v-if="!showMeta">
          mdi-arrow-down-drop-circle-outline
        </v-icon>
        <v-icon color="grey" v-else> mdi-arrow-up-drop-circle-outline </v-icon>
      </v-btn>
    </h4>
    <HealthcheckMetaForm v-show="showMeta" ref="metaform" />
    <h5 v-if="disabled" class="text-center">Finale</h5>
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        Hinzufügen
        <v-icon color="orange darken-3" v-if="e1 === 1"> mdi-pencil </v-icon>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        Beantworten
        <v-icon color="orange darken-3" v-if="e1 === 2"> mdi-pencil </v-icon>
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-12">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-card elevation="4">
                  <v-card-title>
                    Bibliothek
                    <v-icon
                      large
                      color="green darken-2"
                      style="margin-left: auto; margin-right: 0"
                    >
                      mdi-arrow-right-circle
                    </v-icon>
                  </v-card-title>
                  <v-treeview
                    ref="tree"
                    v-model="selection"
                    :items="items"
                    open-all
                    :selectable="!disabled"
                    return-object
                    selection-type="leaf"
                  >
                    <template v-slot:prepend="{ item, open }">
                      <v-icon
                        v-if="item.type === 'question'"
                        color="orange darken-3"
                      >
                        mdi-file-question-outline
                      </v-icon>
                      <v-icon v-else color="indigo darken-3">
                        {{ open ? "mdi-folder-open-outline" : "mdi-folder" }}
                      </v-icon>
                      <sup
                        ><v-chip
                          x-small
                          pill
                          :color="
                            item.name === 'Fragestellungen' ||
                            item.type === 'question'
                              ? 'orange darken-3'
                              : 'indigo darken-3'
                          "
                          outlined
                          v-if="item.prefix"
                          >{{ item.prefix }}</v-chip
                        ></sup
                      >
                    </template>
                  </v-treeview>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card elevation="4">
                  <v-card-title>
                    {{ hc.name }}
                  </v-card-title>
                  <v-treeview :items="hcSelection">
                    <template v-slot:prepend="{ item, open }">
                      <v-icon
                        v-if="item.type === 'question'"
                        color="orange darken-3"
                      >
                        mdi-file-question-outline
                      </v-icon>
                      <v-icon v-else color="indigo darken-3">
                        {{ open ? "mdi-folder-open-outline" : "mdi-folder" }}
                      </v-icon>
                      <sup
                        ><v-chip
                          x-small
                          pill
                          :color="
                            item.name === 'Fragestellungen' ||
                            item.type === 'question'
                              ? 'orange darken-3'
                              : 'indigo darken-3'
                          "
                          outlined
                          v-if="item.prefix"
                          >{{ item.prefix }}</v-chip
                        ></sup
                      >
                    </template>
                  </v-treeview>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="mb-12">
          <v-container>
            <v-row>
              <v-col cols="12">
                <h3>Ansicht: Hierarchisch</h3>
                <v-treeview
                  ref="tree"
                  :items="hcSelection"
                  open-all
                  transition
                  return-object
                  activatable
                  @update:active="selectCategory"
                  selectable
                  selection-type="leaf"
                  v-model="selectedQuestions"
                >
                  <template v-slot:prepend="{ item, open }">
                    <v-icon
                      v-if="item.type === 'question'"
                      color="orange darken-3"
                    >
                      mdi-file-question-outline
                    </v-icon>
                    <v-icon v-else color="indigo darken-3">
                      {{ open ? "mdi-folder-open-outline" : "mdi-folder" }}
                    </v-icon>
                    <sup
                      ><v-chip
                        x-small
                        pill
                        :color="
                          item.type === 'question'
                            ? 'orange darken-3'
                            : 'indigo darken-3'
                        "
                        outlined
                        v-if="item.prefix"
                        >{{ item.prefix }}</v-chip
                      ></sup
                    >
                  </template>
                  <template v-slot:label="{ item }">
                    <v-hover v-slot:default="{ hover }">
                      <div>
                        <span :style="hover ? 'cursor: pointer;' : ''">{{
                          item.name
                        }}</span>
                        <v-icon v-if="hover" class="ml-6">mdi-eye</v-icon>
                      </div>
                    </v-hover>
                  </template>
                </v-treeview>
              </v-col>
              <v-col
                cols="10"
                offset="1"
                class="scale-in-center"
                v-if="activeCategory && activeCategory.name"
              >
                <v-card class="mt-16" elevation="4">
                  <v-card-title>{{ activeCategory.name }}</v-card-title>
                  <v-card-text>Metainformationen zur Kategorie</v-card-text>
                  <HealthcheckCategoryForm :category="activeCategory" />
                </v-card>
              </v-col>
              <v-col cols="12">
                <h3>Ansicht: Tabellarisch</h3>
                <HealthcheckQuestionTable
                  :questionList="selectedQuestions"
                  :healthcheckId="$route.params._id"
                  @activateQuestion="activateQuestion"
                  @allAnswered="allowFinishing"
                />
              </v-col>
              <v-col
                v-if="Object.keys(activeQuestion).length > 0"
                cols="12"
                align="center"
              >
                <v-icon color="primary" large> mdi-arrow-down-circle </v-icon>
              </v-col>
              <v-col
                cols="12"
                class="scale-in-center"
                v-if="Object.keys(activeQuestion).length > 0"
              >
                <v-card class="ma-8" elevation="10">
                  <v-card-title
                    id="question"
                    v-html="getSubtree(activeQuestion)"
                    style="word-break: keep-all"
                  ></v-card-title>
                  <v-card-text>{{ activeQuestion.name }}</v-card-text>
                  <HealthcheckQuestionForm :question="activeQuestion" />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
    <div class="ma-12 text-center">
      <v-btn color="primary" @click="showFinishWarning = true" v-if="e1 === 1">
        Speichern und weiter im Prozess
      </v-btn>
      <v-btn
        v-if="hc.version.slice(-1) === '0'"
        color="primary"
        v-bind="attrs"
        @click="createNewVersion"
      >
        Neue Version beginnen
      </v-btn>
      <v-btn
        color="primary"
        @click="showFinishWarning = true"
        :disabled="!finishable"
        v-if="e1 !== 1 && !disabled"
      >
        Healthcheck abschließen und archivieren
      </v-btn>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" style="cursor: zoom-in">
            <v-icon
              class="ml-4"
              v-if="e1 === 2 && !finishable && !disabled"
              color="primary"
            >
              mdi-comment-question-outline
            </v-icon>
          </span>
        </template>
        <span>Du musst noch Fragen beantworten!</span>
      </v-tooltip>
    </div>
    <v-snackbar v-model="showFinishWarning" :timeout="null">
      <span v-if="e1 === 1"
        >Die Fragen endgültig in den Healthcheck übernehmen? Fragen können
        später <strong>NICHT</strong> hinzugefügt, entfernt oder geändert
        werden.</span
      >
      <span v-else>
        Das Fortfahren bedeuted der Healthcheck wird archiviert und kann
        <strong>NICHT</strong> mehr bearbeitet werden! <br />
        Trotzdem fortfahren?
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" v-bind="attrs" @click="saveAndContinue">
          Ja
        </v-btn>
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="showFinishWarning = false"
        >
          Nein
        </v-btn>
      </template>
    </v-snackbar>
  </v-stepper>
</template>

<script>
import HealthcheckMetaForm from "./HealthcheckMetaForm.vue";
import HealthcheckQuestionForm from "./HealthcheckQuestionForm.vue";
import HealthcheckCategoryForm from "./HealthcheckCategoryForm.vue";
import HealthcheckQuestionTable from "./HealthcheckQuestionTable.vue";
export default {
  components: {
    HealthcheckMetaForm,
    HealthcheckQuestionForm,
    HealthcheckCategoryForm,
    HealthcheckQuestionTable,
  },
  data() {
    return {
      e1: 1,
      items: [],
      selection: [],
      hcSingleObjectSelection: [],
      hcSelection: [],
      hc: {},
      selectedQuestions: [],
      customer: {},
      entity: this.$store.getters.getEntities.HC,
      activeCategory: {},
      activeQuestion: {},
      disabled: this.$router.currentRoute.matched.some(
        (route) => route.meta.disabled
      ),
      showMeta: false,
      finishable: false,
      showFinishWarning: false,
    };
  },
  methods: {
    createNewVersion: function () {
      this.$store
        .dispatch("createNewHcVersion", { id: this.hc._id })
        .then(() => {
          this.$router.push({
            name: "Show.CustomerView",
            params: { _id: this.customer._id },
          });
        });
    },
    saveAndContinue: function () {
      switch (this.e1) {
        // 1: Beim Erstellen eines Healthchecks wird der Fragebogen unwiderruflich gespeichert.
        case 1:
          this.hc = this.$refs.metaform.returnHcMeta();
          this.hc.children = this.hcSelection;
          this.hc.step = this.e1 + 1;
          this.$store
            .dispatch("put", {
              endpoint: this.$store.getters.getEntities.HC,
              load: this.hc,
            })
            .then(() => {
              this.$router.go();
            })
            .catch((err) => console.log(err));
          break;
        case 2:
          // Archivierung eines Healtchecks
          this.hc = this.$refs.metaform.returnHcMeta();
          // hc.version sollte auf nächste finale Version inkrementiert werden
          // Also bspw. 1.0, 2.0, 3.0, ...
          this.hc.version = (parseFloat(this.hc.version) + 0.9)
            .toFixed(1)
            .toString();
          this.hc.step = this.e1;
          this.hc.children = [];
          this.$store
            .dispatch("put", {
              endpoint: this.$store.getters.getEntities.HC,
              load: this.hc,
            })
            .then(() => {
              this.$router.push({
                name: "Show.CustomerView",
                params: { _id: this.customer._id },
              });
            })
            .catch((err) => console.log(err));
          break;
        default:
          break;
      }
    },
    aggregate: function (children) {
      if (!children || children.length === 0) return 0;
      var res = 0;
      for (let child of children) {
        if (child.aggregation) {
          res +=
            child.aggregation / children.filter((el) => el.aggregation).length;
        } else {
          res +=
            this.aggregate(child.children) /
            children.filter((el) => !el.aggregation).length;
        }
      }
      return res;
    },
    selectCategory: function (newCategory) {
      this.activeCategory = null;
      this.$nextTick(() => {
        this.activeCategory = newCategory[0];
      });
    },
    activateQuestion: function (question) {
      this.activeQuestion = question;
      if (Object.keys(this.activeQuestion).length > 0) {
        setTimeout(() => {
          document.getElementById("question").scrollIntoView();
        }, 500);
      }
    },
    getSubtree: function (question) {
      return question.subTree.reduce((a, b) => {
        return a ? b + " > " + a : b;
      }, "");
    },
    allowFinishing: function (finishable) {
      this.finishable = finishable;
    },
  },
  computed: {
    getSelection() {
      return this.items.slice().reduce((a, b) => {
        return a + b;
      }, []);
    },
    loaded() {
      return this.$store.getters.watchLoading;
    },
  },
  watch: {
    selection(newSelection) {
      this.$store
        .dispatch("constructHc", newSelection)
        .then((hc) => {
          this.hcSelection = hc.data;
        })
        .catch((err) => console.log(err));
    },
    loaded() {
      this.$store
        .dispatch("get", { endpoint: this.entity, _id: this.$route.params._id })
        .then((hc) => {
          this.hc = hc.data;
          this.hcSelection = this.hc.children;
          this.customer = this.hc.customer;
          this.activeCategory = null;
          if (hc.version.slice(-1) === "0") {
            this.$router.push({ name: "Show.HealthcheckView" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async e1(newStep) {
      if (newStep === 1) {
        await this.$store
          .dispatch("getAll", this.$store.getters.getEntities.DATAREF)
          .then(async (datarefs) => {
            for (let ref of datarefs.data) {
              await this.$store
                .dispatch("get", {
                  endpoint: this.$store.getters.getEntities.DATAREF,
                  _id: ref._id,
                })
                .then((dataref) => {
                  dataref.data.id = this.$store.getters.getGUI();
                  this.items.push(dataref.data);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (newStep === 2) {
        await this.$store
          .dispatch("get", {
            endpoint: this.entity,
            _id: this.$route.params._id,
          })
          .then((hc) => {
            this.hc = hc.data;
            this.hcSelection = this.hc.children;
            this.customer = this.hc.customer;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  async beforeMount() {
    await this.$store
      .dispatch("getAll", this.$store.getters.getEntities.DATAREF)
      .then(async (datarefs) => {
        for (let ref of datarefs.data) {
          await this.$store
            .dispatch("get", {
              endpoint: this.$store.getters.getEntities.DATAREF,
              _id: ref._id,
            })
            .then((dataref) => {
              dataref.data.id = this.$store.getters.getGUI();
              this.items.push(dataref.data);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // Get Hc
    await this.$store
      .dispatch("get", { endpoint: this.entity, _id: this.$route.params._id })
      .then((hc) => {
        this.hc = hc.data;
        this.hcSelection = this.hc.children;
        this.customer = this.hc.customer;
        if (hc.data.step) {
          if (hc.data.step > 3) {
            this.hc.step = 2;
            if (!this.disabled)
              this.$router.push({ name: "Show.HealthcheckView" });
          }
          this.e1 = hc.data.step;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style></style>
