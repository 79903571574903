<template>
    <main>
        <v-container>
            <v-row v-for="quest, index in questions" :key="quest.id">
                <v-col cols="12" v-if="extendable"><h6 class="text-center">Frage {{index+1}}</h6></v-col>
                <v-col cols="4">
                    <!-- Prefix -->
                    <v-text-field
                    label="Prefix*"
                    outlined
                    v-model="quest.prefix"
                    @input="detectInput"
                    :disabled="disabled"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="8">
                    <!-- Question -->
                    <v-textarea
                    label="Fragestellung"
                    outlined
                    v-model="quest.name"
                    @input="detectInput"
                    :disabled="disabled"
                    >
                    </v-textarea>
                </v-col>
                <v-col offset="4" cols="8">
                    <!-- Info -->
                    <v-textarea
                    label="Zusätzliche Erläuterungen"
                    outlined
                    v-model="quest.info"
                    @input="detectInput"
                    :disabled="disabled"
                    >
                    </v-textarea>
                </v-col>
            <v-divider></v-divider>
            </v-row>
            <v-row v-if="!disabled">
                <v-col cols="12" align="center" justify="center">
                    <v-btn class="text-center" outlined color="green" @click="sendAndClean">
                        Fragen speichern
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </main>
</template>

<script>
export default {
    props: {
        prefix: {type: String, default: ''},
        name: {type: String, default: ''},
        info: {type: String, default: ''},
        extendable: {type: Boolean, default: true},
        disabled: {type: Boolean, default: false}
    },
    data(){
        return {
            questions: [
                {
                    prefix: this.prefix,
                    name: this.name,
                    info: this.info,
                    id: '',
                    type: 'question',
                    children: []
                }
            ]
        }
    },
    methods: {
        detectInput: function(){
            this.$emit('inputChange', this.questions)
        },
        sendAndClean: function(){
            this.$emit('saveQuestions', this.questions)
        },
        appendQuestion: function(){
            this.questions.push({
                    prefix: "",
                    name: "",
                    info: "",
                    id: this.$store.getters.getGUI(),
                    type: 'question',
                    children: []
            })
        }
    },
    beforeMount(){
        this.questions[0].id = this.$store.getters.getGUI()
    }
}
</script>

<style>

</style>