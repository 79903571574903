<template>
    <main>
        <AddButtonCard
        title="User"
        :entity="entity"
        titleToPass="User hinzufügen"
        :viewName="viewName"
        >
            <v-simple-table slot="component">
                <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-left">
                            Rolle
                        </th>
                        <th class="text-left">
                            Ansicht
                        </th>
                        <th class="text-left">
                            Bearbeiten
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="user in sortedUsers"
                        :key="user.name"
                        >
                        <td>{{ user.name }}</td>
                        <td>{{ user.role.name }}</td>
                        <td>
                            <!-- Propagates commit to controller with readonly set to TRUE -->
                            <FormShowButton :viewName="viewName" :params="{_id: user._id}"/>
                        </td>
                        <td>
                            <!-- Propagates commit to controller readonly set to FALSE -->
                            <FormEditButton :viewName="viewName" :params="{_id: user._id}"/>
                        </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </AddButtonCard>
        
    </main>
</template>

<script>
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
import AddButtonCard from '@/components/cards/AddButtonCard.vue'

export default {
    components: {
        FormEditButton,
        FormShowButton,
        AddButtonCard
    },
    data(){
        return {
            viewName: 'UserView',
            entity: this.$store.getters.getEntities.USER,
            users: []
        }
    },
    methods: {
        getAllUsers: function(){
            this.$store.dispatch('getAll', this.entity)
            .then((users)=>{
                this.users = users.data
            })
        }
    },
    computed: {
        sortedUsers(){
            return this.users.slice().sort((a, b)=>{
                if(a.role.name < b.role.name) return -1
                if(a.role.name > b.role.name) return 1
                return 0
            })
        },
        loaded(){
            return this.$store.getters.watchLoading
        }
    },
    watch: {
        loaded(){
            this.getAllUsers()
        }
    },
    beforeMount(){
        this.getAllUsers()
    }
}
</script>

<style>

</style>