<template>
    <main>
        <v-form v-model="valid" ref="form">
            <h4 class="text-center mb-9">{{title}}</h4>
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                        required
                        label="Name*"
                        outlined
                        :rules="[...validation.nameRules, ...validation.notEmpty]"
                        :disabled="disabled"
                        v-model="localUser.name"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                        label="Email*"
                        outlined
                        :rules="[...validation.mailRules, ...validation.notEmpty]"
                        :disabled="disabled"
                        v-model="localUser.mail"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                        label="Unternehmen"
                        outlined
                        :disabled="disabled"
                        v-model="localUser.company"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                        label="Abteilung"
                        outlined
                        :disabled="disabled"
                        v-model="localUser.devision"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col offset="3" cols="6">
                        <WgdRoleList @change="listChanged" :value="localUser.role.name" :disabled="disabled"/>
                    </v-col>
                    <v-col offset="3" cols="6">
                        <v-text-field
                        :label="newUser ? 'Neues Password*': 'Neues Password'"
                        outlined
                        :disabled="disabled"
                        v-model="localUser.password"
                        v-if="!disabled"
                        type="text"
                        :rules="newUser ? [...validation.notEmpty] : []"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col offset="3" cols="6">
                        <FormDeleteButton 
                        v-if="!disabled && !newUser" 
                        verbose
                        :objectToDelete="localUser" 
                        :entity="$store.getters.getEntities.USER" 
                        />
                        <FormSaveButton
                        class="text-center"
                        verbose 
                        :objectToSave="localUser" 
                        :entity="$store.getters.getEntities.USER" 
                        :newObject="newUser"
                        v-if="!disabled"
                        :disabled="!valid"
                        goTo="Edit.UserView"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </main>
</template>

<script>
import WgdRoleList from '@/components/lists/WgdRoleList.vue'
import FormSaveButton from '@/components/buttons/FormSaveButton.vue'
import FormDeleteButton from '@/components/buttons/FormDeleteButton.vue'
export default {
    components:{
        WgdRoleList,
        FormSaveButton,
        FormDeleteButton
    },
    data(){
        return {
            valid: true,
            title: '',
            localUser: {
                _id: '',
                name: '',
                mail: '',
                company: '',
                devision: '',
                role: {
                    name: '',
                    id: ''
                },
                password: ''
            },
            newUser: this.$router.currentRoute.matched.some(route => route.meta.new),
            disabled: this.$router.currentRoute.matched.some(route => route.meta.disabled),
            validation: {
                nameRules: [
                    name => !!name || 'Namen angeben!'
                ],
                mailRules: [
                    mail => /.+@.+\..+/.test(mail) || 'Keine valide Mailadresse.'
                ],
                notEmpty: [
                    input => (input.replace(/ /g,'').length > 0) || 'Bitte etwas eingeben!'
                ]
            }
        } 
    },
    methods: {
        /**
         * Listen to changes to role list
         */
        listChanged: function(role){
            this.localUser.role = JSON.parse(JSON.stringify(role))
        },
        /**
         * Setup the form for different use cases
         */
        setupUser: function(){
            //this.newUser = this.$router.currentRoute.matched.some(route => route.meta.new) // Check if metadata indicated new entry
            // If user is not newly created, setup the given parameters
            if(!this.newUser){ 
                this.$store.dispatch('get', {endpoint: this.$store.getters.getEntities.USER , _id: this.$route.params._id})
                    .then((user)=>{
                        this.localUser = user.data
                        this.localUser['password'] = '' // Password is NOT send by backend, hence set it to empty
                        this.title = this.getTitle()
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
            // If user is new, use default
            }else{
                this.title = this.getTitle()
            }
            // Check if route meta data contains disabled
            //this.disabled = this.$router.currentRoute.matched.some(route => route.meta.disabled)
        },
        getTitle: function(){
            if(this.newUser) return 'Neuen User erstellen'
            return `User ${this.localUser.name} wird ${this.disabled ? 'angezeigt': 'bearbeitet'}`
        },
    },
    computed: {
        loaded(){
            return this.$store.getters.watchLoading
        }
    },
    watch: {
        loaded(){
            // refresh form when a reload is indicated from vuex store
            this.setupUser()
        }
    },
    /**
     * Setup user and form on mount
     */
    beforeMount(){
        this.setupUser()
    }
}
</script>

<style>

</style>