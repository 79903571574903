<template>
    <main>
        <v-row align="center">
            <v-col cols="6">
                <v-checkbox
                v-model="check"
                :label="checkboxLabel"
                color="red"
                ></v-checkbox>
            </v-col>
            <v-col cols="6">
                <v-btn
                outlined
                color="red"
                @click="deleteObject"
                v-if="check"
                >
                    <v-progress-linear
                    indeterminate
                    color="red"
                    v-if="loading"
                    ></v-progress-linear>
                    <span v-else>
                        <span v-if="verbose">Löschen</span>
                        <v-icon>mdi-delete</v-icon>
                    </span>
                </v-btn>
            </v-col>
        </v-row>
    </main>
</template>
<script>
export default {
    props: {
        objectToDelete: {
            type: Object,
            default: ()=>{}
        },
        titleToPass: {
            type: String,
            default: 'Titel hinzufügen'
        },
        entity: {
            type: String,
            default: ''
        },
        verbose: {
            type: Boolean,
            default: false
        },
        checkboxLabel: {
            type: String,
            default: 'Löschen?'
        }
    },
    data(){
        return {
            check: false,
            loading: false
        }
    },
    methods: {
        deleteObject: function(){
            this.loading = true
            this.$store.dispatch('delete',{endpoint: this.entity, load: this.objectToDelete})
                .then(()=>{
                    this.$router.go(-1)
                    this.$store.dispatch('showDefaultSnackbar', {success: true, text: 'Erfolgreich gelöscht!', toggleLoading: false})
                })
                .catch((err)=>{
                    console.log(err)
                })
            this.loading = false
        }
    }
}
</script>