import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import axios from 'axios'

Vue.use(VueRouter)

/**
 * Frontend router setup
 */
const router = new VueRouter({
    /**
     * History mode to avoid reload errors
     */
	mode: 'history',
    routes,
    /**
     * Scroll to the id after '#' in the URL, otherwise start at the top
     */
    scrollBehavior: function(to){
        if(to.hash){
            return {
                selector: to.hash
            }
        }else{
            return { x: 0, y: 0 }
        }
    },
})

/**
 * Before each routing, check if user is authenticated to access the site
 */
router.beforeEach( async (to, from, next) => {
    // Check if auth is required in route metadata
    if (to.matched.some(record => record.meta.requiresAuth)){
        // test if already logged in and set login status accordingly
        await axios.get(store.getters.getBaseUrl+'/checklogin')
            .then((res)=>{
                store.commit("setAuthData", {
                    isSignedIn: true,
                    name: res.data.name,
                    role: res.data.role,
                })
            })
            // If unauthorized, reset
            .catch(()=>{
                store.commit("setAuthData", {
                    isSignedIn: false,
                    name: "",
                    role: "",
                })
            })

        // if not, redirect to login page.
        if (!store.getters.isLoggedIn) {
            return next({ name: 'LoginView' }) // You shall not pass!
        } else {
            return next() // User is logged in
        }
    } else {
      next() // Site does not require auth
    }
})

export default router