<template>
    <main>
        <v-expansion-panels>
            <!--Create User Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Erstellen von Usern <FormCreateButton class="wgd__showbutton-only" :absolute="false" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Zum Erstellen eines Users müssen alle mit (*) gekennzeichneten Felder der Eingabemaske ausgefüllt werden. <br>
                        <strong>Zu beachten:</strong> Die <b>Email</b> des Users muss <b>einzigartig</b> sein und dient bei der Anmeldung als <b>Username!</b>
                        <div class="mt-2">
                            Das eingebene Passwort dient als Initialpassword und sollte nach der ersten Anmeldung geändert werden!
                        </div>
                        <div class="mt-2">
                            Die vergebene <b>Systemrolle im Dropdownmenu</b> dient zur Zeit nur als 'kosmetische' Trennung der Nutzerrollen. Sowohl 'Consultants' als auch 'System Admin'
                            haben identische Rechte in der App.
                        </div>
                        <div class="mt-4">
                            <b>Nach der Erstellung</b> wird man zur Bearbeitungsmaske des erstellten Users weitergeleitet und der neu erstellte User erscheint in der Liste links neben der Eingabemaske.
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Edit User Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Bearbeiten von Usern <FormEditButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Alle Felder des Users können beliebig bearbeitet werden.<br>
                        <strong>Zu beachten:</strong> Die Änderung der <b>Email</b> des Users ändert auch den <b>Usernamen</b> und somit die Anmeldedaten.
                        <div class="mt-2">
                            <strong>Zu beachten:</strong> Das Passwort des Users wird <b>nur</b> bei einer neuen Eingabe geändert. Lässt man das Feld leer, bleibt das <b>alte Passwort bestehen!</b>
                        </div>
                        <div class="mt-2">
                            Das Löschen des Users entfernt ihn aus dem System und verhindert eine erneute Anmeldung mit den gelöschten Anmeldedaten!
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Show User Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Anzeigen von Usern <FormShowButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Die Daten des ausgewählten Users werden im 'read only' Modus angezeigt. Verhindert versehentliche Änderungen der Daten
                        und ermöglicht (später) das Anzeigen von Daten, die zur selben Zeit von einem anderen User bearbeitet werden.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </main>
</template>

<script>
import FormCreateButton from '@/components/buttons/FormCreateButton.vue'
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
export default {
    components: {
        FormCreateButton,
        FormEditButton,
        FormShowButton,
    }
}
</script>

<style scoped>
.wgd__showbutton-only{
    margin-right: 1rem;
    margin-left: 1rem;
    display: inline;
}
</style>