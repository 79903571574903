<template>
    <main>
        <v-expansion-panels>
            <!--Create Customer Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Erstellen von Kunden <FormCreateButton class="wgd__showbutton-only" :absolute="false" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Zum Erstellen eines Kunden müssen alle mit (*) gekennzeichneten Felder der Eingabemaske ausgefüllt werden. <br>
                        <strong>Zu beachten:</strong> Der <b>Name</b> des Kunden muss <b>einzigartig</b> sein!
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Edit Customer Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Bearbeiten von Kunden <FormEditButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Alle Felder des Kunden können beliebig bearbeitet werden.<br>
                        <div class="mt-2">
                            <strong>Zu beachten:</strong> Das Löschen eines Kunden löscht <b>nicht</b> die für diesen Kunden erstellten Healthchecks. (Archivierung) <br>
                            Sollten Healthchecks eines gelöschten Kunden zugänglich gemacht werden müssen, bedarf es einer besonderen Extraktion aus der Datenbank.
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Show Customer Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Anzeigen von Kunden <FormShowButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Die Daten des ausgewählten Kunden werden im 'read only' Modus angezeigt. Verhindert versehentliche Änderungen der Daten
                        und ermöglicht (später) das Anzeigen von Daten, die zur selben Zeit von einem anderen User bearbeitet werden.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </main>
</template>

<script>
import FormCreateButton from '@/components/buttons/FormCreateButton.vue'
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
export default {
    components: {
        FormCreateButton,
        FormEditButton,
        FormShowButton,
    }
}
</script>

<style scoped>
.wgd__showbutton-only{
    margin-right: 1rem;
    margin-left: 1rem;
    display: inline;
}
</style>