<template>
    <main>
        <v-container>
            <v-row>
                <v-col :offset="filter.showFilter ? '1':'9'" :cols="filter.showFilter ? '10':'3'" class="slide-in-right">
                    <v-card class="mt-16" elevation="4">
                        <v-card-title>Filter
                        <v-btn
                            class="mx-2"
                            small
                            icon
                            @click="filter.showFilter = !filter.showFilter"
                        >
                            <v-icon color="grey" v-if="!filter.showFilter">
                            mdi-arrow-down-drop-circle-outline
                            </v-icon>
                            <v-icon color="grey" v-else>
                            mdi-arrow-up-drop-circle-outline
                            </v-icon>
                        </v-btn>
                        <sub><i v-if="filter.search || filter.showUnanswered || filter.showUncommented" style="color: green;">aktiv ({{filteredQuestions.length}})</i></sub>
                        </v-card-title>
                        <v-card-text v-show="filter.showFilter">
                        Filtere Fragen nach Interviewpartner und ihrem Status
                        <v-text-field
                            v-model="filter.search"
                            label="Interviewpartner (Name oder Mail)"
                            light
                            hide-details
                            filled
                            clearable
                            clear-icon="mdi-close-circle-outline"
                            single-line
                            class="ma-6"
                        ></v-text-field>
                        <v-checkbox
                            class="ma-6"
                            v-model="filter.showUnanswered"
                            label="Nur unbeantwortet"
                        ></v-checkbox>
                        <v-checkbox
                            class="ma-6"
                            v-model="filter.showUnapplicable"
                            label="Nur unzutreffend"
                        ></v-checkbox>
                        <v-checkbox
                            class="ma-6"
                            v-model="filter.showUncommented"
                            label="Noch ohne Handlungsempfehlung"
                        ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-simple-table
                    :height="filteredQuestions.length > 4 ? '350':'200'"
                    fixed-header
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Prefix
                                    </th>
                                    <th class="text-left">
                                        Fragen {{filteredQuestions.length}}/{{questions.length}}
                                    </th>
                                    <th class="text-left">
                                        Pfad
                                    </th>
                                    <th class="text-left">
                                        Handlungsempfehlung {{filteredQuestions.filter(el => !!el.wgdataComment).length}}/{{filteredQuestions.length}}
                                        <v-icon color="green" v-if="filteredQuestions.length !== 0 && filteredQuestions.filter(el => !!el.wgdataComment).length === filteredQuestions.length">
                                            mdi-star-circle
                                        </v-icon>
                                    </th>
                                    <th class="text-left">
                                        Nicht zutreffend {{filteredQuestions.filter(el => el.notApplicable).length}}/{{filteredQuestions.length}}
                                    </th>
                                    <th class="text-left">
                                        Beantwortet {{filteredQuestions.filter(el => el.answered).length}}/{{filteredQuestions.length}}
                                        <v-icon color="green" v-if="filteredQuestions.length !== 0 && filteredQuestions.filter(el => el.answered).length === filteredQuestions.length">
                                            mdi-star-circle
                                        </v-icon>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="question in filteredQuestions"
                                :key="question._id"
                                @click="emitQuestion(question)"
                                :class="question._id === activeQuestion._id ? 'wgd__td-active':'wgd__td'"
                                >
                                    <td>{{ question.prefix }}</td>
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                v-bind="attrs"
                                                v-on="on"
                                                style="cursor: zoom-in;"
                                                ><u>{{ question.name.substring(0, 40) + '...' }}</u></span>
                                            </template>
                                            <span>{{question.name}}</span>
                                        </v-tooltip>
                                        
                                    </td>
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                v-bind="attrs"
                                                v-on="on"
                                                style="cursor: zoom-in;"
                                                ><u>Pfad</u></span>
                                            </template>
                                            <span>{{getSubtree(question)}}</span>
                                        </v-tooltip>
                                    </td>
                                    <td>
                                        <v-checkbox
                                            readonly
                                            v-model="question.wgdataComment"
                                        ></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox
                                            readonly
                                            v-model="question.notApplicable"
                                            v-if="question.answered"
                                        ></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox
                                            readonly
                                            v-model="question.answered"
                                        ></v-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>
        
    </main>
</template>

<script>
    export default{
        props: {
            healthcheckId: {
                type: String,
                required: true
            },
            questionList: {
                type: Array,
                default: ()=>[]
            }
        },
        data(){
            return {
                questions: [],
                activeQuestion: {},
                filter: {
                    showFilter: false,
                    search: '',
                    showUnanswered: false,
                    showUncommented: false,
                    showUnapplicable: false
                },
                

            }
        },
        methods: {
            emitQuestion: function(question){
                if(this.activeQuestion._id !== question._id){
                    this.activeQuestion = question
                    this.$emit('activateQuestion', question)
                }else{
                    this.activeQuestion = {}
                    this.$emit('activateQuestion', {})
                }
            },
            fetchQuestions(){
                this.$store.dispatch('getByQuery', {endpoint: this.$store.getters.getEntities.QUEST, params: {healthcheck: this.healthcheckId}})
                    .then(questions => {
                        this.questions = questions.data
                        if(this.questions.length === this.questions.filter(el => el.answered).length){
                            this.$emit('allAnswered', true)
                        }else{
                            this.$emit('allAnswered', false)
                        }
                    })
            },
            getSubtree: function(question){
                return question.subTree.reduce((a, b) => {
                return a ? b+' > '+a : b
                }, '')
            },
        },
        computed: {
            loaded(){
                return this.$store.getters.watchLoading
            },
            filteredQuestions(){
                var questions = this.questionList.length > 0 ? this.questionList : this.questions
                return questions
                    .filter(item => this.filter.search ? ((item.interviewPartnerName && item.interviewPartnerName.toLowerCase().includes(this.filter.search.toLowerCase())) || (item.interviewPartnerMail && item.interviewPartnerMail.toLowerCase().includes(this.filter.search.toLowerCase()))) : true) // only apply filter if search is set
                    .filter(item => this.filter.showUncommented ? !item.wgdataComment : true) // only apply filter if showUncommented is set
                    .filter(item => this.filter.showUnanswered ? !item.answered : true) // only apply filter if showUnanswered is set
                    .filter(item => this.filter.showUnapplicable ? item.notApplicable : true)
            },
        },
        watch: {
            loaded(){
                this.fetchQuestions()
            },
            filteredQuestions(newQuestions){
                if(newQuestions.length === 0 || newQuestions.filter(el => el._id === this.activeQuestion._id).length < 1){
                    this.activeQuestion = {}
                    this.$emit('activateQuestion', {})
                }
            },
        },
        beforeMount(){
            this.fetchQuestions()
        }
    }
</script>
<style>
    .wgd__td-active{
        cursor: pointer;
        background-color: blanchedalmond;
    }
    .wgd__td{
        cursor: pointer;
    }
</style>