<template>
    <v-app>
        <v-main>
            <h1 class="text-center gradient__text scale-in-center">Risiko&shy;management Health&shy;Check App</h1>
            <v-divider class="wgd__seperator"></v-divider>
            <router-view class="ma-9" name="UserManual"></router-view>
            <router-view name="MainHeader"></router-view>
            <router-view class="wgd_default-view"></router-view> <!-- Entrypoint of the views -->
            <router-view class="wgd__footer" name="MainFooter"></router-view>
            <DefaultSnackbar />
        </v-main>
    </v-app>
</template>

<script>
import DefaultSnackbar from '@/components/snackbars/DefaultSnackbar.vue'
export default {
    name: 'App',
    components: {
        DefaultSnackbar,
    },
};
</script>

<style global>
    /**
     * Variables to use in the app
     */
    :root{
        /** Basic stuff */
        --font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        --font-size-desktop: 20px;
        --font-size-tablet: 18px;
        --font-size-mobile: 14px;

        /** Main colors for the theme */
        --theme-primary-color: #0d962f;
        --theme-secondary-color: #b7ca09;

        --font-primary-color: #7f8c8d;
    }

    #app{
        padding: 5rem 0 5rem 0;
        max-width: 1920px;
        margin: auto;
    }

    /** Change font size dependend on device */
    html{
        font-size: var(--font-size-desktop);
        
    }

    @media screen and (max-width: 550px) {
        html{
            font-size: var(--font-size-mobile);
        }
    }

    @media screen and (max-width: 700px) and (min-width: 551px) {
        html{
            font-size: var(--font-size-tablet);
        }
    }

    /** General settings */
    *{
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        scroll-behavior: smooth;
        font-family: var(--font-family);
    }
    body{
        background: #ecf0f1;
        color: var(--font-primary-color);
    }

    /** Headings */
    h1{
        font-size: 3rem;
    }
    h2{
        font-size: 2.5rem;
    }
    h3{
        font-size: 2rem;
        color: var(--font-primary-color);
    }
    h4{
        font-size: 1.5rem;
        color: var(--font-primary-color);
    }
    h5{
        font-size: 1.3rem;
    }
    h6{
        font-size: 1.1rem;
    }

    .wgd__footer{
        margin: 2rem;
    }

    .wgd__seperator{
        margin: 2rem;
    }

    .wgd__logout{
        margin-right: 3rem;
    }

    /**Applies a gradient text depending on computed style*/
    .gradient__text{

    /* ff 3.6+ */
    background:-moz-linear-gradient(89.97deg, var(--theme-primary-color) 1.84%, var(--theme-secondary-color) 102.67%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(89.97deg, var(--theme-primary-color) 1.84%, var(--theme-secondary-color) 102.67%);

    /* opera 11.10+ */
    background:-o-linear-gradient(89.97deg, var(--theme-primary-color) 1.84%, var(--theme-secondary-color) 102.67%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--theme-secondary-color)', endColorstr='var(--theme-primary-color)', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(89.97deg, var(--theme-primary-color) 1.84%, var(--theme-secondary-color) 102.67%);

    /* global 94%+ browsers support */
    background:linear-gradient(89.97deg, var(--theme-primary-color) 1.84%, var(--theme-secondary-color) 102.67%);

    /** Apply gradient colors properly */
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
    transition: --theme-primary-color 3s, --theme-secondary-color 3s;

    }

    /**
     * Animations
     */

    .scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /* ----------------------------------------------
    * Generated by Animista on 2022-3-18 19:40:48
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    /**
    * ----------------------------------------
    * animation scale-in-center
    * ----------------------------------------
    */
    @-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    }
    @keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    }


    .slide-in-left {
        -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /* ----------------------------------------------
    * Generated by Animista on 2022-3-27 11:53:18
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    /**
    * ----------------------------------------
    * animation slide-in-left
    * ----------------------------------------
    */
    @-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    }

    .slide-in-right {
        -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /* ----------------------------------------------
    * Generated by Animista on 2022-3-27 11:55:19
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    /**
    * ----------------------------------------
    * animation slide-in-right
    * ----------------------------------------
    */
    @-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    }

</style>