<template>
    <main>
        Das Dashboard ermöglicht den Zugang zu den Datenentitäten, welche die App verwaltet.

        <h6 class="mt-2">User</h6>
        Ermöglicht das Erstellen und Ändern von System Usern. Ein neu erstellter User kann sich mit dem bei der Erstellung vergebenen Passwort anmelden. <br>
        <b>Alle</b> Consultants und System Administratoren können die Passwörter <b>aller</b> User ändern.

        <h6 class="mt-2">Kunden</h6>
        Verwaltet Kunden, für die ein oder mehrere Healthchecks erstellt werden sollen. Um Healthchecks eines Kunden einsehen zu können, muss der Kunde zunächst über den 'Ansicht' oder 'Bearbeiten'-Button
        ausgewählt werden. (Mandantensicherheit)

        <h6 class="mt-2">Bibliothek</h6>
        Die Bibliothek verwaltet die Datenreferenzquellen (z.B. VAIT o.ä), welche als Basis für die Erstellung von Healthchecks dienen.

        <h6 class="mt-2">Healthchecks</h6>
        Healthchecks werden auf Basis von Datenreferenzquellen aus der Bibliothek erstellt und legen durch Bewertungen von Fragestellungen einen 'Reifegrad' des Kunden in der jeweiligen Kategorie fest.
        Sie sind erreichbar, nachdem man den jeweiligen Kunden über 'Ansicht' oder 'Bearbeiten' aufgerufen hat.

        <h5 class="mt-2">Buttons</h5>
            <div class="wgd__showbutton-row"><FormCreateButton class="wgd__showbutton-only" :absolute="false" forShowOnly/>Erstellt eine neuen Datensatz der jeweiligen Entität</div>
            <div class="wgd__showbutton-row"><FormEditButton forShowOnly class="wgd__showbutton-only"/> Ermöglicht die Bearbeitung des jeweiligen Datensatzes</div>
            <div class="wgd__showbutton-row"><FormShowButton forShowOnly class="wgd__showbutton-only"/> Ermöglicht die Ansicht im 'read only' Modus des jeweiligen Datensatzes</div>
            <div class="wgd__showbutton-row"><FormCreateButton :fab="false" outlined forShowOnly :absolute="false" class="wgd__showbutton-only"/> Ermöglicht die Erstellung eines Healthchecks für den jeweiligen Kunden</div>
    </main>
</template>

<script>
import FormCreateButton from '@/components/buttons/FormCreateButton.vue'
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
export default {
    components: {
        FormCreateButton,
        FormEditButton,
        FormShowButton,
    }
}
</script>

<style scoped>
.wgd__showbutton-row{
    margin-top: 1rem;
}
.wgd__showbutton-only{
    margin-right: 1rem;
    display: inline;
}

</style>