<template>
    <main>
        <v-expansion-panels class="mb-8">
            <!--General Dataref Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Allgemeines zu Datenreferenzquellen</h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Datenreferenzquellen werden als hierarchische Daten im System gespeichert. Die oberste Hierarchiebene ist hierbei die Datenreferenzquelle selbst (z.B. VAIT). Diese kann aus beliebig vielen 
                        <b>Unterkategorien</b> bestehen. Die Kategorieebenen werden als zur Übersichtlichkeit als <b>Ordnerstruktur</b> angezeigt.
                    </div>
                    <div>
                        <strong>Zu beachten:</strong> Die Ordnerstruktur von Unterkategorien kann für zusätzliche Übersichtlichkeit durch einen Klick auf den danebenliegenden Pfeil verborgen werden. 
                        Ob Unterkategorien verborgen sind, wird durch den blauen Ordner vor dem Kategorienamen im Katalog angegeben.
                        <PictogramTreeviewFolder/>
                    </div>
                    <div class="mt-2">
                        <PictogramTreeviewQuestion/>
                    </div>
                    <div class="mt-2">
                        <strong>Zu beachten:</strong> Kategorien und Fragestellungen können nicht auf der selben Ebene vorhanden sein. Die App gibt entsprechend Hilfestellung und verhindert das Erstellen 
                        von Kategorien, wenn bereits Fragestellungen auf der Ebene vorhanden sind und vice versa.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Create Dataref Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Erstellen von Datenreferenzquellen <FormCreateButton class="wgd__showbutton-only" :absolute="false" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Zum Erstellen einer Datenreferenzquellen müssen alle mit (*) gekennzeichneten Felder der Eingabemaske ausgefüllt werden. <br>
                        <strong>Zu beachten:</strong> Die Felder <b>Prefix</b> und <b>Name</b> der Eingabemaske referenzieren die oberste Kategorieebene des Kataloges.
                        Auf oberster Ebene gibt es nur eine Kategorie (die Datenreferenzquelle selbst)
                    </div>
                    <CsvUploadDataref></CsvUploadDataref>
                    <div class="mt-2">
                        Klicke auf Kategorien, um die Kategorie manuell zu verändern oder Unterkategorien/Fragestellungen hinzuzufügen.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Edit Dataref Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Bearbeiten von Datenreferenzquellen <FormEditButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Alle Kategorien/Fragestellungen einer Datenreferenzquellen können beliebig bearbeitet werden.<br>
                        <CsvUploadDataref></CsvUploadDataref>
                    </div>
                    <div class="mt-2">
                        Klicke auf Kategorien, um die Kategorie manuell zu verändern oder Unterkategorien/Fragestellungen hinzuzufügen.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Show Dataref Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Anzeigen von Datenreferenzquellen <FormShowButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Die Daten der ausgewählten Datenreferenzquelle werden im 'read only' Modus angezeigt. Verhindert versehentliche Änderungen der Daten
                        und ermöglicht (später) das Anzeigen von Daten, die zur selben Zeit von einem anderen User bearbeitet werden.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <a class="mt-2" href="/Datenreferenzquelle_csv_beispiel.csv" download>Hier klicken , um eine beispielhafte csv-Datei mit korrektem Format herunterzuladen.</a>
    </main>
</template>

<script>
import FormCreateButton from '@/components/buttons/FormCreateButton.vue'
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
import CsvUploadDataref from './snippets/CsvUploadDataref.vue'
import PictogramTreeviewFolder from './snippets/PictogramTreeviewFolder.vue'
import PictogramTreeviewQuestion from './snippets/PictogramTreeviewQuestion.vue'
export default {
    components: {
        FormCreateButton,
        FormEditButton,
        FormShowButton,
        CsvUploadDataref,
        PictogramTreeviewFolder,
        PictogramTreeviewQuestion
    }
}
</script>

<style scoped>
.wgd__showbutton-only{
    margin-right: 1rem;
    margin-left: 1rem;
    display: inline;
}
</style>