/**
 * Mapping of routes to views
 */

/**
 * Import components
 */
import LoginForm from '@/components/forms/LoginForm.vue'
import UserTable from '@/components/entities/user/UserTable.vue'
import UserForm from '@/components/entities/user/UserForm.vue'
import CustomerForm from '@/components/entities/customer/CustomerForm.vue'
import CustomerTable from '@/components/entities/customer/CustomerTable.vue'
import HealthcheckTable from '@/components/entities/healthcheck/HealthcheckTable.vue'
import HealthcheckMetaForm from '@/components/entities/healthcheck/HealthcheckMetaForm.vue'
import HealthcheckProcess from '@/components/entities/healthcheck/HealthcheckProcess.vue'
import BackToDashboardButton from '@/components/buttons/BackToDashboardButton.vue'
import SideBySideView from '@/views/SideBySideView.vue'
import DashboardView from '@/views/DashboardView.vue'
import SingleIndentedView from '@/views/SingleIndentedView.vue'
import SingleView from '@/views/SingleView.vue'
import LibraryTreeview from '@/components/entities/library/LibraryTreeview.vue'
import LibraryTable from '@/components/entities/library/LibraryTable.vue'
import LogoutSnippet from '@/components/snippets/LogoutSnippet.vue'
import UserManual from '@/components/usermanual/UserManual.vue'

const routes = [
    { path: '/', redirect: {name: 'HomeDashboardView'} }, // Redirect root to dashboard because of annoying router bug
    {
        path: '',
        components:{ 
            default: SingleIndentedView
        },
        meta: {
            requiresAuth: false
        },
        children: [
            {
                path: '/login',
                components: {
                    SingleItem: LoginForm
                },
                name: 'LoginView',
            },
          ],
    },
    {
        path: '',
        components:{ 
            default: SingleView,
            UserManual
        },
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/dataref',
                components: {
                    SingleViewItem: LibraryTreeview
                },
                name: 'DatarefView',
            },
          ],
    },
    {
        path: '',
        components: {
            default: DashboardView,
            MainHeader: LogoutSnippet,
            UserManual
        },
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/dashboard',
                components: {
                    FirstDashboardItem: UserTable,
                    SecondDashboardItem: CustomerTable,
                    ThirdDashboardItem: LibraryTable,
                },
                name: 'HomeDashboardView',
            },
          ],
    },
    {
        path: '', // url prefix to indicate 's'how only mode
        meta: {
            requiresAuth: true,
            disabled: false,
            new: false,
        },
        components: {
            default: SideBySideView,
            MainFooter: BackToDashboardButton,
            UserManual
        },
        children: [
            // Show user form and table
            {
                path: '/show/user/:_id',
                components: {
                    FirstSideBySideItem: UserTable,
                    SecondSideBySideItem: UserForm,
                },
                name: 'Show.UserView',
            },
            // Show customer form and healthchecks
            {
                path: '/show/customer/:_id',
                components: {
                    FirstSideBySideItem: CustomerForm,
                    SecondSideBySideItem: HealthcheckTable,
                },
                name: 'Show.CustomerView',
            },
            // Show dataref
            {
                path: '/show/dataref/:_id',
                components: {
                    FirstSideBySideItem: LibraryTreeview,
                },
                name: 'Show.LibraryView',
                meta: {
                    single: true
                }
            },
            // Show healthcheck process
            {
                path: '/show/healthcheck/:_id',
                components: {
                    FirstSideBySideItem: HealthcheckProcess,
                },
                name: 'Show.HealthcheckView',
                meta: {
                    single: true
                }
            },
        ],
    },
    {
        path: '', // url prefix to indicate 'e'dit mode
        meta: {
            requiresAuth: true,
            disabled: false,
            new: false,
        },
        components: {
            default: SideBySideView,
            MainFooter: BackToDashboardButton,
            UserManual
        },
        children: [
            // Edit user
            {
                path: '/edit/user/:_id',
                components: {
                    FirstSideBySideItem: UserTable,
                    SecondSideBySideItem: UserForm,
                },
                name: 'Edit.UserView',
            },
            // Edit customer
            {
                path: '/edit/customer/:_id',
                components: {
                    FirstSideBySideItem: CustomerForm,
                    SecondSideBySideItem: HealthcheckTable,
                },
                name: 'Edit.CustomerView',
            },
            // Edit dataref
            {
                path: '/edit/dataref/:_id',
                components: {
                    FirstSideBySideItem: LibraryTreeview,
                },
                name: 'Edit.LibraryView',
                meta: {
                    single: true
                }
            },
            // Healthcheck process
            {
                path: '/edit/healthcheck/:_id',
                components: {
                    FirstSideBySideItem: HealthcheckProcess,
                },
                name: 'Edit.HealthcheckView',
                meta: {
                    single: true
                }
            },
          ],
    },
    {
        path: '',
        meta: {
            requiresAuth: true,
            disabled: false,
            new: true,
        },
        components: {
            default: SideBySideView,
            MainFooter: BackToDashboardButton,
            UserManual
        },
        props: true,
        children: [
            // Create new user
            {
                path: '/create/user',
                components: {
                    FirstSideBySideItem: UserTable,
                    SecondSideBySideItem: UserForm,
                },
                name: 'Create.UserView',
            },
            // Create new customer
            {
                path: '/create/customer',
                components: {
                    FirstSideBySideItem: CustomerTable,
                    SecondSideBySideItem: CustomerForm,
                },
                name: 'Create.CustomerView',
            },
            // Create new library entry
            {
                path: '/create/library',
                components: {
                    FirstSideBySideItem: LibraryTreeview
                },
                name: 'Create.LibraryView',
                meta: {
                    single: true
                }
            },
            // Create new healthcheck
            {
                path: '/create/healthcheck/:_id', // IMPORTANT: View needs id of the customer, never show hcs which are not from the customer
                components: {
                    FirstSideBySideItem: HealthcheckTable,
                    SecondSideBySideItem: HealthcheckMetaForm,
                },
                name: 'Create.HealthcheckView',
            },
          ],
    },
]

export default routes