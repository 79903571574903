<template>
    <div class="text-right wgd__logout">Hallo {{$store.state.auth.name}}! 
        <v-chip
        class="ma-2"
        small
        color="red"
        text-color="red"
        outlined
        @click="logout"
        >
        Logout
        </v-chip>
    </div>
</template>

<script>
export default {
    methods: {
        logout: function(){
            this.$store.dispatch('logout')
                .then(()=>{
                    this.$store.dispatch('showDefaultSnackbar', {success: true, text: 'Erfolgreich abgemeldet!'})
                    this.$router.push({name: 'LoginView'})
                })
                .catch((err)=>{
                    this.$store.dispatch('showDefaultSnackbar', {success: false, text: `Fehler bei der Abmeldung: ${err}`})
                })
        }
    }
}
</script>
