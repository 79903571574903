<template>
    <main>
        <v-container>
            <v-row>
                <v-col cols="3">
                    <h5 class="text-center">Datenreferenzquelle</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset="3" cols="2">
                    <!-- Prefix for the dataref-->
                    <v-text-field
                    label="Prefix"
                    outlined
                    v-model="items[0].prefix"
                    :disabled="disabled"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="4">
                    <!-- Prefix for the dataref-->
                    <v-text-field
                    label="Datenreferenzquelle*"
                    outlined
                    v-model="items[0].name"
                    :disabled="disabled"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset="3" cols="6">
                    <v-file-input
                    v-if="!disabled"
                    @change="setCsv"
                    label="csv hochladen"
                    truncate-length="50"
                    accept="text/csv"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="left" cols="12">
                    <v-card
                    elevation="5"
                    width="100%"
                    >
                    <v-card-title>
                        Katalog
                        <v-col offset="1" cols="10">
                            <FormDeleteButton
                            class="ml-3"
                            v-if="!disabled && !newDataref" 
                            verbose
                            :objectToDelete="items[0]" 
                            :entity="$store.getters.getEntities.DATAREF"
                            checkboxLabel="Gesamten Katalog endgültig löschen?"
                            />
                        </v-col>
                    </v-card-title>
                    <v-card-text>Klicke auf den Namen einer Kategorie, um Unterkategorien oder Fragestellungen hinzuzufügen.</v-card-text>
                    <v-text-field
                        v-model="search"
                        label="Katalog durchsuchen"
                        light
                        hide-details
                        filled
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        single-line
                    ></v-text-field>
                    <v-treeview
                        ref="tree"
                        :search="search"
                        :filter="filterSearch"
                        :items="items"
                        open-all
                        return-object
                        activatable
                        @update:active="showDialog"
                        :active="activeElement"
                    >
                        <template v-slot:prepend="{ item, open }">
                            <v-icon v-if="item.type === 'question'" color="orange darken-3">
                                mdi-file-question-outline
                            </v-icon>
                            <v-icon v-else  color="indigo darken-3">
                                {{ open ? 'mdi-folder-open-outline' : 'mdi-folder' }}
                            </v-icon>
                            <sup><v-chip x-small pill :color="item.type === 'question' ? 'orange darken-3':'indigo darken-3'" outlined v-if="item.prefix">{{item.prefix}}</v-chip></sup>
                        </template>
                        <template v-slot:label="{ item }">
                            <v-hover v-slot:default="{ hover }">
                            <div>
                                <span :style="hover ? 'cursor: pointer;' : ''">{{item.name}}</span>
                                <v-icon v-if="hover" class="ml-6" color="green">mdi-eye-plus</v-icon>
                            </div>
                            </v-hover>
                        </template>
                    </v-treeview>
                    </v-card>
                </v-col>
                <v-col offset="3" cols="6">
                    <v-dialog v-model="sheet" inset @click:outside="sheet = false" width="60%">
                        <v-sheet
                        color="white"
                        elevation="12"
                        >
                        <v-col v-for="entry in activeElement" :key="entry.id" cols="12">
                            <v-row>
                                <v-col cols="12">
                                    {{entry.type !== 'question' ? `Kategorie` : 'Fragestellung' }} ändern
                                </v-col>
                            </v-row>
                            <LibraryCategoryForm
                            :name="entry.name" 
                            :prefix="entry.prefix" 
                            v-if="entry.type !== 'question'"
                            @saveCategory="changeCategory($event, entry)"
                            :disabled="disabled"
                            />
                            <LibraryQuestionForm
                            :name="entry.name"
                            :prefix="entry.prefix"
                            :info="entry.info"
                            :extendable="false"
                            v-else
                            @saveQuestions="changeQuestion($event, entry)"
                            :disabled="disabled"
                            />
                            <v-row align="center" justify="center">
                                <v-col cols="2">
                                    <v-checkbox
                                    v-model="deletion"
                                    label="Löschen?"
                                    color="red"
                                    v-if="!disabled"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn
                                    outlined
                                    color="red"
                                    @click="triggerDeletion(entry)"
                                    v-if="deletion"
                                    >
                                        <span>Löschen</span>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-card class="pa-9" v-if="!disabled && activeElement[0] && activeElement[0].type !== 'question'">
                            <v-row align="center" justify="center">
                                <v-btn-toggle
                                    v-model="childrenToAdd"
                                    tile
                                    color="blue accent-3"
                                    group
                                >
                                    <v-btn                                             
                                    :disabled="activeElement[0] && activeElement[0].children.some(el => el.type === 'category')" 
                                    value="question">
                                    Fragestellung hinzufügen
                                    </v-btn>

                                    <v-btn 
                                    value="category" 
                                    :disabled="activeElement[0] && activeElement[0].children.some(el => el.type === 'question')">
                                    Unterkategorie hinzufügen
                                    </v-btn>
                                </v-btn-toggle>
                            </v-row>
                            <v-row align="center" justify="center" v-if="childrenToAdd === 'question'">
                                <v-col cols="12">
                                    <h5 class="text-center">Fragestellungen hinzufügen</h5>
                                </v-col>
                                <v-col cols="12">
                                    <LibraryQuestionForm @saveQuestions="addQuestions"/>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" v-if="childrenToAdd === 'category'">
                                <v-col cols="12">
                                    <h5 class="text-center">Hierarchiebene hinzufügen</h5>
                                </v-col>
                                <v-col cols="12">
                                    <LibraryCategoryForm @saveCategory="addChild"/>
                                </v-col>
                            </v-row>
                        </v-card>
                        </v-sheet>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <FormSaveButton
                v-if="!disabled"
                verbose 
                :objectToSave="items[0]" 
                :entity="$store.getters.getEntities.DATAREF"
                :newObject="newDataref"
                goTo="HomeDashboardView"
                />
            </v-row>
        </v-container>
    </main>
</template>

<script>
import LibraryCategoryForm from './LibraryCategoryForm.vue'
import LibraryQuestionForm from './LibraryQuestionForm.vue'
import FormSaveButton from '@/components/buttons/FormSaveButton.vue'
import FormDeleteButton from '@/components/buttons/FormDeleteButton.vue'
export default {
    components: {
        LibraryCategoryForm,
        LibraryQuestionForm,
        FormSaveButton,
        FormDeleteButton
    },
    data(){
        return {
            csv: '',
            search: '',
            deletionTriggert: false,
            deletion: false,
            selection: [],
            sheet: false,
            activeElement: [],
            childrenToAdd: '',
            // Gets meta data from router if its supposed to create a blank form
            newDataref: this.$router.currentRoute.matched.some(route => route.meta.new),
            // Gets meta data from router if its supposed to disable elements of the form
            disabled: this.$router.currentRoute.matched.some(route => route.meta.disabled),
            items:[
                {
                    id: this.$store.getters.getGUI(),
                    prefix: '',
                    name: 'Datenreferenzquelle',
                    type: 'dataref',
                    children: [],
                },
            ],
        }
    },
    methods: {
        setCsv: function(csv){
            this.csv = ''

            if(!csv) return

            const f = new FileReader()
            f.readAsText(csv)
            f.onloadend = ()=>{
                this.csv += f.result
            }
        },
        triggerDeletion: function(category){
            for(let item of this.items){
                this.deleteNode(category, item.children) 
            }
            this.sheet = false
            this.deletion = false
        },
        deleteNode: function(category, currentArray){
            const delItems = [];

            currentArray.forEach(element => {
                if (element.id === category.id) {
                    delItems.push(element);
                }
                if (element.children != undefined && element.children != null && element.children.length > 0){
                    this.deleteNode(category, element.children);
                }
            });

            delItems.forEach(item => {
                currentArray.splice(currentArray.indexOf(item), 1);
            });
        },
        changeCategory: function(newCategory, category){
            category.name = newCategory.name
            category.prefix = newCategory.prefix
            this.exitDialog()
        },
        changeQuestion: function(newQuestion, question){
            question.name = newQuestion[0].name
            question.prefix = newQuestion[0].prefix
            question.info = newQuestion[0].info
            this.exitDialog()
        },
        addChild: function(category){
            if(this.activeElement[0] && this.activeElement[0].children){
                this.activeElement[0].children.push(JSON.parse(JSON.stringify(category)))
            }
            this.$refs.tree.updateAll(true)
            this.exitDialog()
        },
        addQuestions: function(questions){
            if(this.activeElement[0] && this.activeElement[0].children){
                for(const quest of questions){
                    this.activeElement[0].children.push(JSON.parse(JSON.stringify(quest)))
                }
            }
            this.$refs.tree.updateAll(true)
            this.exitDialog()
        },
        showDialog: function(item){
            if(Object.keys(item).length === 0) return
            this.sheet = true
            this.activeElement = item
            this.childrenToAdd = ''
            this.deletion = false
        },
        exitDialog: function(){
            this.activeElement = []
            this.sheet = false
        }
    },
    computed: {
        csvEntries(){
            return this.csv
        },
        filterSearch(){
            return (item, search) => item.prefix.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase())
        }
    },
    watch: {
        sheet(show){
            if(!show){
                this.activeElement = []
            }
        },
        csv(newCsv){
            if(!newCsv) return
            // Get rows
            var rows = newCsv.split('\r\n')
            // Remove headers
            rows.shift()
            // Sort input into tree
            rows.map(line => {
                let entry = line.split(';')
                let combinedEntries = []
                for(let i = 0; i<entry.length; i+=2){
                    if(i === entry.length-3){ // prefix of the question
                        combinedEntries.push({
                            prefix: entry[i],
                            name: entry[i+1],
                            id: this.$store.getters.getGUI(),
                            children: [],
                            type: 'question',
                            info: entry[i+2]
                        })
                        i++
                    }else{
                        combinedEntries.push({
                            prefix: entry[i],
                            name: entry[i+1],
                            id: this.$store.getters.getGUI(),
                            children: [],
                            type: 'category'
                        })
                    }
                    
                }
                // push into tree
                combinedEntries
                .reduce((column, next) => {
                    if(column.children.filter(child => child.name === next.name).length > 0){
                        return column.children.reduce((acc, elem)=>{
                            if(elem.name === next.name){
                                return elem
                            }else{
                                return acc
                            }
                        }, {})
                    }
                    column.children.push(next)
                    return next
                    
                }, this.items[0])
            })
            setTimeout(()=>{
                this.$refs.tree.updateAll(true)
            },500)
            
        }
    },
    beforeMount(){
        if(!this.newDataref){
            this.$store.dispatch('get', {endpoint: this.$store.getters.getEntities.DATAREF, _id: this.$route.params._id})
                    .then((dataref)=>{
                        this.items.pop()
                        this.items.push(dataref.data)
                        console.log(dataref.data)
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
        }
        setTimeout(()=>{
                this.$refs.tree.updateAll(true)
        },500)
    }
}
</script>

<style>

</style>