<template>
    <main>
        <AddButtonCard
        :title="`${customer.name} Healthchecks`"
        :entity="entity"
        :titleToPass="`Neuen Healthcheck für ${customer.name} starten`"
        :viewName="viewName"
        >
            <v-simple-table slot="component">        
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Name
                            </th>
                            <th class="text-left">
                                Version
                            </th>
                            <th class="text-left">
                                Ansicht
                            </th>
                            <th class="text-left">
                                Bearbeiten
                            </th>
                            <th class="text-left">
                                Download
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="hc in sortedHcs"
                        :key="hc._id"
                        >
                        <td>{{ hc.name }}</td>
                        <td>{{ hc.version }}</td>
                        <td>
                            <FormShowButton :viewName="viewName" :params="{_id: hc._id}"/>
                        </td>
                        <td>
                            <FormEditButton :viewName="viewName" :params="{_id: hc._id}"/>
                        </td>
                        <td>
                            <DownloadButton :objectToDownload="hc" :entity="$store.getters.getEntities.HC"/>
                        </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </AddButtonCard>
    </main>
</template>

<script>
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
import AddButtonCard from '@/components/cards/AddButtonCard.vue'
import DownloadButton from '@/components/buttons/DownloadButton.vue'

export default {
    components: {
        FormEditButton,
        FormShowButton,
        AddButtonCard,
        DownloadButton
    },
    data(){
        return {
            viewName: 'HealthcheckView',
            entity: this.$store.getters.getEntities.HC,
            customer: {},
            hcs: [
                {
                    name: 'VAIT - HC',
                    version: '0.1',
                    _id: 'ef4efgrg'
                }
            ]
        }
    },
    methods: {
        getAllHcsByCustomer: function(){
            this.$store.dispatch('getByQuery', {endpoint: this.entity, params:{customer: this.customer._id}})
                .then((hcs) => {
                    this.hcs = hcs.data
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    computed: {
        sortedHcs(){
            return this.hcs.slice().sort((a,b) => {
                if(!a.endDate && !b.endDate) return 0
                if(!a.endDate) return 1
                if(!b.endDate) return -1
                if(new Date(a.endDate) < new Date(b.endDate)) return 1
                if(new Date(a.endDate) > new Date(b.endDate)) return -1
                return 0
            })
        },
        loaded(){
            return this.$store.getters.watchLoading
        }
    },
    watch: {
        loaded(){
            this.getAllHcsByCustomer()
        }
    },
    async beforeMount(){
        await this.$store.dispatch('get', {endpoint: this.$store.getters.getEntities.CUSTOMER, _id: this.$route.params._id})
            .then(customer => {
                this.customer = customer.data
            })
            .catch(err => {
                console.log(err)
            })
        this.getAllHcsByCustomer()
    }

}
</script>

<style>

</style>