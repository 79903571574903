<template>
    <main>
        <v-form>
            <h6 class="text-center " style="color: green;" v-if="hc.version === '1.0'">
                <!-- Final versions, which could be edited 
                    TODO: Each version x.0 is final, not only 1.0
                -->
                <v-icon color="green" large>
                    mdi-star-circle
                </v-icon>
                Healthcheck {{hc.name}} für {{hc.customer.name}} abgeschlossen!
            </h6>
            <h4 v-else class="text-center mb-9">{{getTitle()}}</h4>
            <v-container>
                <v-row>
                    <v-col cols="8">
                        <v-text-field
                        label="Name"
                        outlined
                        :disabled="disabled"
                        v-model="hc.name"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                        label="Version"
                        outlined
                        :disabled="true"
                        v-model="hc.version"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <h6 class="text-center">Start Datum</h6>
                    </v-col>
                    <v-col cols="6">
                        <h6 class="text-center">End Datum</h6>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-date-picker
                        v-model="hc.startDate"
                        :first-day-of-week="1"
                        locale="de-de"
                        no-title
                        :show-current="false"
                        :disabled="disabled"
                        ></v-date-picker>
                    </v-col>
                    <v-col cols="6">
                        <v-date-picker
                        v-model="hc.endDate"
                        :first-day-of-week="1"
                        locale="de-de"
                        no-title
                        :show-current="false"
                        :disabled="disabled"
                        ></v-date-picker>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                        label="Zusätzliche Informationen"
                        outlined
                        v-model="hc.info"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <FormSaveButton
                        verbose
                        :objectToSave="hc"
                        :entity="entity"
                        goTo="Edit.HealthcheckView"
                        :modelRefId="this.hc.customer._id"
                        :newObject="newHc"
                        v-if="!disabled"
                        :params="{customer: hc.customer._id}"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </main>
</template>

<script>
import FormSaveButton from '@/components/buttons/FormSaveButton.vue'

export default {
    components: {
        FormSaveButton
    },
    data(){
        return {
            disabled: this.$router.currentRoute.matched.some(route => route.meta.disabled),
            newHc: this.$router.currentRoute.matched.some(route => route.meta.new),
            entity: this.$store.getters.getEntities.HC,
            hc: {
                id: this.$store.getters.getGUI(),
                name: '',
                version: '0.1',
                startDate: '',
                endDate: '',
                info: '',
                customer: {},
                children: []
            }
        }
    },
    methods: {
        getTitle: function(){
            if(this.newHc) return `Neuer Healthcheck für ${this.hc.customer.name}`
            return `Healthcheck ${this.hc.name} bearbeiten`
        },
        returnHcMeta: function(){
            return this.hc
        }
    },
    beforeMount(){
        if(this.newHc){
            // Get customer
            this.$store.dispatch('get', {endpoint: this.$store.getters.getEntities.CUSTOMER, _id: this.$route.params._id})
                .then(customer => {
                    this.hc.customer = customer.data
                })
                .catch(err => {
                    console.log(err)
                })
        }else{
            // Get Hc
            this.$store.dispatch('get', {endpoint: this.entity, _id: this.$route.params._id})
                .then(hc => {
                    this.hc = hc.data
                    this.customer = this.hc.customer
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
}
</script>

<style>

</style>