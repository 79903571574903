<template>
  <main>
      <v-form>
            <v-container>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                        label="Prefix"
                        outlined
                        disabled
                        v-model="localCategory.prefix"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field
                        label="Name"
                        outlined
                        disabled
                        v-model="localCategory.name"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h6 class="text-center">{{localCategory.aggregation ? localCategory.aggregation:aggregate(localCategory.children)}}</h6>
                    </v-col>
                    <v-col v-if="getDiagram" offset="1" cols="10" :key="diagrammKey">
                        <Radar
                        :chart-data="chartData"
                        :chart-options="chartOptions"
                        :key="diagrammKey"
                        ref="radar"
                        v-if="reRender"
                        />
                    </v-col>
                    <v-col v-else offset="1" cols="10">
                        <Bar
                        :chart-data="chartData"
                        :chart-options="chartOptions"
                        :height="100"
                        :key="diagrammKey+1"
                        ref="bar"
                        v-if="reRender"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
  </main>
</template>

<script>
import { Radar, Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, registerables} from 'chart.js'

ChartJS.register(...registerables)

export default {
    components: {
        Radar,
        Bar
    },
    props: {
        category: {type: Object, required: true}
    },
    data(){
        return {
            localCategory: JSON.parse(JSON.stringify(this.category)),
            diagrammKey: 0,
            reRender: true,
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        label: 'Punkteverteilung',
                        fill: true,
                        backgroundColor: "",
                        borderColor: "",
                        pointBackgroundColor: '',
                        pointBorderColor: '',
                        pointHoverBackgroundColor: '',
                        pointHoverBorderColor: '',
                        pointHitRadius: '10'
                    }
                ],
            },
            chartOptions: {
                indexAxis: 'y',
                responsive: true,
                legend: {
                    labels: {
                        fontColor: "#000",
                    }
                },
                scales:{
                    r: {
                        beginAtZero: true,
                        max: 10,
                        min: 0,
                        angleLines: {
                            display: true
                        },
                        ticks: {
                            display: true,
                            stepSize: 2
                        }
                    },
                },
            }
        }
    },
    watch: {
        category(newCategory){
            this.reRender = false
            this.localCategory = JSON.parse(JSON.stringify(newCategory))
            this.chartData.labels = this.getLabels
            this.chartData.datasets[0].data =  this.getData
            this.diagrammKey += 1
            this.$nextTick(() => {
                this.reRender = true
            })
        }
    },
    methods: {
        aggregate: function(children){
            if(!children || children.length === 0) return 0
            
            var res = 0
            for(const child of children){
                if(child.answered && !child.notApplicable){
                    res += child.aggregation/children.filter(el => el.answered && !el.notApplicable).length
                }else{
                    res +=  this.aggregate(child.children)/children.filter(el => !(el.answered && !el.notApplicable)).length
                }
            }
            return (Math.round(res*10)/10)
        },
        subTreeIsNotApplicable: function(children){
            if(!children || children.length === 0) return false
            if(children.every(el => el.notApplicable)) return true
            this.subTreeIsNotApplicable(children.children)
        },
        getColor(){
            const agg = this.aggregate(this.localCategory.children)
            if(agg < 4) return '#e74c3c80'
            if(agg >= 4 && agg < 8) return '#f1c40f80'
            return '#2ecc7180'
        }
    },
    computed: {
        getDiagram(){
            return this.localCategory.children && this.localCategory.children.length > 2
        },
        getLabels(){
            return this.localCategory.children.map(el => {
                return el.type === 'question' ? el.prefix : el.name
                })
        },
        getData(){
            return this.localCategory.children.map(el => {
                return el.type === 'question' ? el.aggregation : this.aggregate(el.children)
            })
        },
        getChartData(){
            return this.chartData
        },
        getChartOptions(){
            return this.chartOptions
        },
        
    },
    beforeMount(){
        this.chartData.labels = this.localCategory.children.map(el => {
            return el.type === 'question' ? el.notApplicable ? el.prefix+' (nicht angewendet)' : el.prefix : el.name
        })
        this.chartData.datasets[0].data =  this.localCategory.children.map(el => {
            return el.type === 'question' ? el.aggregation : this.aggregate(el.children)
        })
        const color = this.getColor()
        this.chartData.datasets[0].backgroundColor = color
        this.chartData.datasets[0].borderColor = color
        this.chartData.datasets[0].pointBackgroundColor = color
        this.chartData.datasets[0].pointBorderColor = color
        this.chartData.datasets[0].pointHoverBackgroundColor = color
        this.chartData.datasets[0].pointHoverBorderColor = color
    }
}
</script>