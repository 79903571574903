<template>
    <main>
        <v-container>
            <v-row>
                <v-col cols="4">
                    <!-- Prefix -->
                    <v-text-field
                    label="Prefix*"
                    outlined
                    v-model="category.prefix"
                    @input="detectInput"
                    :disabled="disabled"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="8">
                    <!-- Name -->
                    <v-text-field
                    label="Kategoriename*"
                    outlined
                    v-model="category.name"
                    @input="detectInput"
                    :disabled="disabled"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="!disabled">
                <v-col cols="12" align="center">
                    <v-btn class="text-center" color="green" outlined @click="sendAndClean"
                    >Speichern</v-btn>
                </v-col>
            </v-row>
        </v-container>        
    </main>
</template>

<script>
export default {
    props: {
        name: {type: String, default: ''},
        prefix: {type: String, default: ''},
        disabled: {type: Boolean, default: false}
    },
    data(){
        return {
            category: {
                name: this.name,
                prefix: this.prefix,
                id: '',
                type: 'category',
                children: []
            }
        }
    },
    methods: {
        sendAndClean: function(){
            this.$emit('saveCategory', this.category)
        },
        detectInput: function(){
            this.$emit('inputChange', this.category)
        }
    },
    beforeMount(){
        this.category.id = this.$store.getters.getGUI()
    }
}
</script>

<style>

</style>