<template>
    <main>
        <v-btn
        outlined
        color="blue-grey"
        @click="$router.push({name: 'HomeDashboardView'})"
        >
            <v-icon>mdi-arrow-left-circle</v-icon> <span v-if="verbose">Zurück zum Dashboard</span> 
        </v-btn>
    </main>
</template>

<script>
export default {
    props: {
        verbose: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style>

</style>