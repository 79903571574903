<template>
    <main>
        <v-btn
        outlined
        color="green"
        @click="save"
        :disabled="disabled"
        >
            <v-progress-linear
            indeterminate
            color="green"
            v-if="loading"
            ></v-progress-linear>
            <span v-else>
                <v-icon>mdi-content-save</v-icon> 
                <span v-if="verbose">Speichern</span>
            </span>
        </v-btn>
    </main>
</template>
<script>
export default {
    props: {
        objectToSave: {
            type: Object,
            default: ()=>{}
        },
        params: {
            type: Object,
            default: ()=>{}
        },
        entity: {
            type: String,
            required: true
        },
        goTo: {
            type: String,
            required: true
        },
        newObject: {
            type: Boolean,
            default: false
        },
        verbose: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            loading: false
        }
    },
    methods: {
        save: function(){
            this.loading = true
            // If new object, perform post request, else put
            if(this.newObject){
                this.$store.dispatch('post', {endpoint: this.entity, load: this.objectToSave, params: this.params})
                    .then((res)=>{
                        this.$emit('savedSuccess', res.data)
                        this.$store.dispatch('showDefaultSnackbar', {success: true, text: 'Erfolgreich erstellt!'})
                        this.$router.push({name: this.goTo, params: {_id: res.data._id}})
                    })
                    .catch((err)=>{
                        this.$emit('savedFailure', err)
                        this.$store.dispatch('showDefaultSnackbar', {success: false, text: `Fehler beim erstellen!: ${err}`})
                    })
            }else{
                this.$store.dispatch('put', {endpoint: this.entity, load: this.objectToSave})
                    .then((res)=>{
                        this.$emit('savedSuccess', res.data)
                        this.$store.dispatch('showDefaultSnackbar', {success: true, text: 'Erfolgreich gespeichert!'})
                    })
                    .catch((err)=>{
                        this.$emit('savedFailure', err)
                        this.$store.dispatch('showDefaultSnackbar', {success: false, text: `Fehler beim speichern!: ${err}`})
                        console.log(err)
                    })
            }
           this.loading = false
        }
    }
}
</script>
