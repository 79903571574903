<template>
    <main>
        <v-btn
        outlined
        color="blue"
        @click="forShowOnly ? undefined : download()"
        :disabled="disabled"
        >
            <v-progress-linear
            indeterminate
            color="blue"
            v-if="loading"
            ></v-progress-linear>
            <span v-else>
                <v-icon>mdi-cloud-download</v-icon> 
                <span v-if="verbose">Download</span>
            </span>
        </v-btn>
    </main>
</template>
<script>
export default {
    props: {
        objectToDownload: {
            type: Object,
            default: ()=>{}
        },
        verbose: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        entity: {
            type: String,
            default: ''
        },
        forShowOnly: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            loading: false
        }
    },
    methods: {
        download: function(){
            this.loading = true
            
            this.$store.dispatch('download', {endpoint: this.entity, _id: this.objectToDownload._id})
                .then((response) => {
                    console.log('here')
                    this.$store.dispatch('showDefaultSnackbar', {success: true, text: 'Download gestartet!'})
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${new Date().toLocaleDateString('de-de')}_${this.objectToDownload.name}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(err => {
                    this.$store.dispatch('showDefaultSnackbar', {success: true, text: 'Fehler: Download nicht gestartet! '+err})
                })

            this.loading = false
        }
    }
}
</script>