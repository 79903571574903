<template>
    <main>
        <v-icon @click="open = !open" v-show="!open" large color="grey lighten">mdi-book-account</v-icon>
        <v-card v-if="open" elevation="14" class="scale-in-center">
            <v-card-title>Nutzerhandbuch</v-card-title>
            <v-row  class="wgd__usermanual-text">
                <v-col cols="3">
                    <v-card
                    elevation="12"
                    width="256"
                    >
                        <v-navigation-drawer
                            floating
                            permanent
                        >
                            <v-list
                            dense
                            >
                                <v-list-item
                                    v-for="item in items"
                                    :key="item.title"
                                    link
                                    @click="active = item.title"
                                >
                                    <v-list-item-icon>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-navigation-drawer>
                    </v-card>
                </v-col>
                <v-col cols="9">
                    <div v-if="!active">Klicke auf einen Link in der Navigation, um mehr zu erfahren!</div>
                    <DashboardManualSite v-if="active === 'Dashboard'" />
                    <UserManualSite v-if="active === 'User'" />
                    <CustomerManualSite v-if="active === 'Kunden'" />
                    <DatarefManualSite v-if="active === 'Bibliothek'" />
                    <HealthcheckManualSite v-if="active === 'Healthchecks'" />
                </v-col>
            </v-row>
            <v-btn
                color="red"
                top
                absolute
                right
                fab
                text
                @click="open = false"
                v-if="open"
                class="mt-9"
            >
                <v-icon>mdi-close-octagon</v-icon>
            </v-btn>
        </v-card>
    </main>
</template>

<script>
import DashboardManualSite from './sites/DashboardManualSite.vue'
import UserManualSite from './sites/UserManualSite.vue'
import CustomerManualSite from './sites/CustomerManualSite.vue'
import DatarefManualSite from './sites/DatarefManualSite.vue'
import HealthcheckManualSite from './sites/HealthcheckManualSite.vue'
export default {
    components: {
        DashboardManualSite,
        UserManualSite,
        CustomerManualSite,
        DatarefManualSite,
        HealthcheckManualSite
    },
    data(){
        return {
            open: false,
            active: '',
            items: [
                {
                    title: "Dashboard",
                    icon: "mdi-view-dashboard"
                },
                {
                    title: "User",
                    icon: "mdi-account"
                },
                {
                    title: "Kunden",
                    icon: "mdi-account-cash"
                },
                {
                    title: "Bibliothek",
                    icon: "mdi-database"
                },
                {
                    title: "Healthchecks",
                    icon: "mdi-heart-pulse"
                },
            ]
        }
    }

}
</script>

<style scoped>
    .wgd__usermanual-text{
        padding: 2rem;
    }
</style>