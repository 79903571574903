<template>
    <main>
        <v-btn
        outlined
        color="orange"
        @click="forShowOnly ? undefined:$router.push({name: `Edit.${viewName}`, params:params})"
        >
            <span v-if="verbose">Bearbeiten</span> <v-icon>mdi-clipboard-edit</v-icon>
        </v-btn>
    </main>
</template>

<script>
export default {
    props: {
        params: {
            type: Object,
            default: ()=>{}
        },
        viewName: {
            type: String,
            default: ''
        },
        verbose: {
            type: Boolean,
            default: false
        },
        forShowOnly: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>