<template>
    <!-- Card component with attached button to the bottom right -->
    <!-- Emits the event "cardAdd" -->
    <main>
        <v-card
        elevation="5"
        class="wgd__addbutton-card"
        >
            <v-card-title>{{title}}</v-card-title>

            <!-- Slot to insert custom component -->
            <slot name="component"></slot>

            <FormCreateButton :viewName="viewName"/>
        </v-card>
    </main>
</template>

<script>
import FormCreateButton from '@/components/buttons/FormCreateButton.vue'
export default {
    components: {
        FormCreateButton
    },
    props: {
        // Card title
        title: {
            type: String,
            default: ''
        },
        params: {
            type: Object,
            default: ()=>{}
        },
        viewName: {
            type: String,
            default: ''
        },
    }

}
</script>

<style scoped>

.wgd__addbutton-card{
    padding: 1rem;
    margin: 1rem;
}

</style>