<template>
    <main>
        <v-expansion-panels class="mb-8">
            <!--General Hc Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Allgemeines zu Healthchecks</h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Healthchecks sind Teilmengen der Datenreferenzquellen und fügen den Fragestellungen Datenfelder (wie 'Bewertungen' etc.) hinzu.
                        Da Healthchecks <b>immer</b> an einen Kunden gebunden sind und eine Mandantensicherheit hergestellt werden soll, sind Healthchecks nur über die Übersicht des jeweiligen Kunden erreichbar.
                        <div class="mt-2">
                            Klicke auf den 'Ansichtmodus' oder 'Bearbeitenmodus' eines Kunden, um die Healthchecks des Kunden einsehen zu können.
                        </div>
                        <div class="mt-2">
                            Healthchecks können über einen 'Quick Create' Button <FormCreateButton :fab="false" outlined forShowOnly :absolute="false" class="wgd__showbutton-only"/> direkt vom Dashboard aus für den jeweiligen Kunden erstellt werden.
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Create Hc Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Erstellen von Healthchecks <FormCreateButton class="wgd__showbutton-only" :absolute="false" forShowOnly/> / <FormCreateButton :fab="false" outlined forShowOnly :absolute="false" class="wgd__showbutton-only"/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Bei der initialen Erstellung eines Healthchecks werden zunächst nur die Metadaten (Namen, Zeitraum, zusätzliche Infos) des Healthchecks angegeben. <br>
                        <strong>Zu beachten:</strong> Der Name eines Healthchecks muss für jeden Kunden <b>einzigartig</b> sein, allerdings können sich Namen von Healthchecks für verschiedene Kunden doppeln.
                    </div>
                    <div class="mt-2">
                        Sobald die Metadaten für den Healthcheck eingegeben und 'Speichern' angeklickt wurde, erfolgt eine Weiterleitung zum Healthcheck Prozess. <br>
                        Für Infos über den Healthcheck-Prozess siehe den Abschnitt 'Bearbeiten von Healthchecks' in diesem Nutzerhandbuch.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Edit Hc Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Bearbeiten von Healthchecks <FormEditButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Der Healthcheck-Prozess besteht aus zwei Schritten. Im ersten Schritt werden die zu prüfenden Fragestellungen der Datenreferenzquellen (oder Teilmengen davon) hinzugefügt. <br>
                        Im zweiten Schritt werden die ausgewählten Fragestellungen dann bewertet, beantwortet und kommentiert.
                    </div>
                    <div class="mt-8">
                        <h6>Schritt 1: Hinzufügen</h6>
                        In diesem Schritt werden Fragen aus der Bibliothek dem Healthcheck hinzugefügt. <br>
                        In der linken Spalte befinden sich alle Datenreferenzquellen, die sich gerade im System befinden. Die Ordnerstruktur ist diesselbe, wie beim managen von Datenreferenzquellen der Bibliothek.
                        <div>
                            <strong>Zur Erinnerung: </strong> <PictogramTreeviewFolder></PictogramTreeviewFolder> <PictogramTreeviewQuestion/>
                        </div>
                        <div class="mt-2">
                            Klicke auf die Pfeile neben den Ordner, um die darunter liegende Struktur freizugeben.
                        </div>
                        <div class="mt-2">
                            Markiere das Kästchen des jeweiligen Ordners, um die Kategorie und alle Unterkategorien dem Healthcheck hinzuzufügen. Überprüfe jederzeit auf der rechten Seite des Prozessschrittes, ob der ausgewählte Fragenkatalog 
                            den Anforderungen entspricht. <br>
                        </div>
                        <div class="mt-2">
                            Sobald der Healthcheck vollständig ist, klicke 'Speichern und weiter im Prozess' an, um in den nächsten Prozessschritt zu gelangen. <br>
                            <strong>Achtung:</strong> Eine nachträgliche Änderung des Fragenkataloges im Healthcheck ist <b>nicht</b> möglich! Sollte eine nachträgliche Änderung vorgenommen werden wollen, muss der Healthcheck <b>gelöscht und neu angelegt</b> werden.
                        </div>
                    </div>
                    <div class="mt-8">
                        <h6>Schritt 2: Beantworten</h6>
                        In diesem Schritt werden hinzugefügte Fragen aus dem Healthcheck beantwortet. <br>
                        Zur Übersichtlichkeit existieren zwei verschiedene Ansichten auf die zu beantwortenden Fragen:
                        <div>
                            <strong>Ansicht: Hierarchisch</strong> <br> Genau wie beim managen von Datenreferenzquellen und 'Schritt 1: Hinzufügen' im Healthcheck-Prozess wird der Fragenkatalog als Ordnerstruktur angezeigt. <br>
                            <strong>Zur Erinnerung: </strong> <PictogramTreeviewFolder></PictogramTreeviewFolder> <PictogramTreeviewQuestion/>
                        </div>
                        <div class="mt-2">
                            Klicke auf die Pfeile neben den Ordner, um die darunter liegende Struktur freizugeben.
                        </div>
                        <div class="mt-2">
                            Das Markieren des Kästchens vor einer Kategorie sorgt dafür, dass die tabellarische Ansicht (mehr dazu weiter unten) nach Fragen aus der entsprechenden Kategorie <b>gefiltert</b> werden.<br>
                        </div>
                        <div class="mt-2">
                            <strong>Wichtig:</strong> Das <b>klicken auf den Kategorienamen</b> zeigt eine vorübergehende Aggregation der Bewertungen der Fragen <b>aller</b> Unterkategorien an. Dies ermöglicht es dem Kunden einen Zwischenstand der jeweiligen Kategorie zu präsentieren. <br>
                            Das angezeigte Diagramm hängt von der Anzahl von direkten Unterkategorien ab: <br>
                            <b>Balkendiagramm:</b> Für weniger als 3 Unterkategorien, da eine Darstellung mit Radardiagramm unübersichtlich ist. <br>
                            <b>Radardiagramm:</b> Für 3 oder mehr Unterkategorien.
                        </div>
                        <div class="mt-2">
                            <strong>Ansicht: Tabellarisch</strong> <br> 
                            Die Tabelle zeigt Informationen zu den Fragen in einer übersichtlichen Form an. <br>
                            Außerdem wählt man in dieser Ansicht die zu beantwortende Frage durch <b>anklicken</b> aus.
                        </div>
                        <div class="mt-2">
                            <strong>Kurze Erläuterung der Spalten:</strong> <br>
                            <i>Prefix:</i> Prefix der Fragestellung, entnommen aus der Bibliothek <br>
                            <i>Fragen:</i> Ausformulierte Fragstellung (hover zum Anzeigen der vollständigen Fragestellung), 3/10 beschreibt wie viele Fragen gerade gefiltert sind <br>
                            <i>Pfad:</i> Zeigt beim hovern alle Oberkategorien als Schnellübersicht an <br>
                            <i>Handlungsempfehlungen:</i> Checkbox, ob bereits eine Handlungsempfehlung seitens der WG-DATA abgegeben wurde <br>
                            <i>Nicht zutreffend:</i> Checkbox, ob eine Frage als nicht zutreffend markiert wurde <br>
                            <i>Beantwortet:</i> Checkbox, ob die jeweilige Frage bereits beantwortet wurde. <br>
                        </div>
                        <div class="mt-4">
                            <strong>Tabelle Filtern:</strong> <br>
                            Klicke auf das graue Dreieck im Filter-Kästchen, um die Filterfunktionalitäten anzuzeigen. <br>
                            Bei Texteingaben wird <b>nicht</b> case-sensitive gefiltert. <br>
                            Die gesetzten Filter <b>ergänzen sich</b> mit den ausgewählten Kategorien aus der hierarischen Ansicht.
                        </div>
                        <div class="mt-4">
                            <strong>Beenden des Prozesses:</strong> <br>
                            Alle Fragen müssen zumindest beantwortet sein (empfohlen sind auch Kommentare), um den Button 'Healthcheck Abschliessen und Archivieren' drücken zu können. <br>
                            Danach gilt der Healthcheck als erfolgreich durchgeführt und kann danach <b>nicht mehr bearbeitet werden!</b>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Show Dataref Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Anzeigen von Healthchecks <FormShowButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Die Daten des ausgewählten Healthchecks werden im 'read only' Modus angezeigt. Verhindert versehentliche Änderungen der Daten
                        und ermöglicht (später) das Anzeigen von Daten, die zur selben Zeit von einem anderen User bearbeitet werden.
                        <br>
                        Nach der erfolgreichen Archivierung eines Healthchecks, ist dieser nur noch im 'read only' Modus verfügbar. Dies wird durch eine Versionsnummer von <b>1.0</b> angezeigt.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Download Hc Explanation-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h6>Das Downloaden von Healthchecks <DownloadButton class="wgd__showbutton-only" forShowOnly/></h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        Über einen Kunden kann auf die Liste von Healthchecks zugegriffen werden. Der Download-Button ermöglich den download des Healthchecks (auch als Zwischenstand <b>vor</b> der Archivierung) als Excel Liste. <br>
                        Die Excel Liste ist Unterteilt in die verschiedenen Unterkategorien und bildet ebenfalls ein Diagramm mit den Bewertungen der jeweiligen Unterkategorien ab. <br>
                        Die Dauer des Downloads kann, je nach Datenmenge des Healthchecks, stark variieren. (Wenige Millisekunden bis einige Sekunden)
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </main>
</template>

<script>
import FormCreateButton from '@/components/buttons/FormCreateButton.vue'
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
import DownloadButton from '@/components/buttons/DownloadButton.vue'
import PictogramTreeviewFolder from './snippets/PictogramTreeviewFolder.vue'
import PictogramTreeviewQuestion from './snippets/PictogramTreeviewQuestion.vue'
export default {
    components: {
        FormCreateButton,
        FormEditButton,
        FormShowButton,
        DownloadButton,
        PictogramTreeviewFolder,
        PictogramTreeviewQuestion
    }
}
</script>

<style scoped>
.wgd__showbutton-only{
    margin-right: 1rem;
    margin-left: 1rem;
    display: inline;
}
</style>