<template>
    <main>
        <v-btn
        outlined
        color="blue"
        @click="forShowOnly ? undefined:$router.push({name: `Show.${viewName}`, params:params})"
        >
            <span v-if="verbose">Anzeigen</span> <v-icon>mdi-eye</v-icon>
        </v-btn>
    </main>
</template>

<script>
export default {
    props: {
        params: {
            type: Object,
            default: ()=>{}
        },
        viewName: {
            type: String,
            default: ''
        },
        verbose: {
            type: Boolean,
            default: false
        },
        forShowOnly: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>