<template>
    <main>
        <v-btn
        color="green"
        :dark="dark"
        :absolute="absolute"
        :bottom="bottom"
        :right="right"
        :fab="fab"
        :outlined="outlined"
        @click="forShowOnly ? undefined:$router.push({name: `Create.${viewName}`, params: params})"
        >
            <span v-if="verbose">Erstellen</span> <v-icon>mdi-folder-plus</v-icon>
        </v-btn>
    </main>
</template>

<script>
export default {
    props: {
        params: {
            type: Object,
            default: ()=>{}
        },
        viewName: {
            type: String,
            default: ''
        },
        verbose: {
            type: Boolean,
            default: false
        },
        absolute: {
            type: Boolean,
            default: true
        },
        bottom: {
            type: Boolean,
            default: true
        },
        right: {
            type: Boolean,
            default: true
        },
        fab: {
            type: Boolean,
            default: true
        },
        dark: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        },
        forShowOnly: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>