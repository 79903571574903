<template>
    <main>
        <v-form v-model="valid" ref="form">
            <h4 class="text-center mb-9">{{title}}</h4>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h5 class="text-center">Unternehmensdaten</h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-row> <!-- Name and Info column -->
                            <v-col cols="12">
                                <!-- Name -->
                                <v-text-field
                                required
                                label="Name*"
                                outlined
                                :rules="[...validation.nameRules, ...validation.notEmpty]"
                                :disabled="disabled"
                                v-model="localCustomer.name"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <!-- Info -->
                                <v-textarea
                                label="Zusatzinformationen"
                                outlined
                                :disabled="disabled"
                                v-model="localCustomer.info"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="2" align="center">
                        <v-divider
                        vertical
                        ></v-divider>
                    </v-col>
                    <v-col cols="5">
                        <v-row>
                            <v-col cols="12">
                                <!-- Address -->
                                <v-text-field
                                label="Adresse"
                                outlined
                                :disabled="disabled"
                                v-model="localCustomer.address"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <!-- Location -->
                                <v-text-field
                                label="Ort"
                                outlined
                                :disabled="disabled"
                                v-model="localCustomer.location"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <!-- Zip -->
                                <v-text-field
                                label="Plz"
                                outlined
                                :disabled="disabled"
                                v-model="localCustomer.zip"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-divider></v-divider>
                        <h5 class="text-center">Ansprechpartner Daten</h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                        label="Name"
                        outlined
                        :disabled="disabled"
                        v-model="localCustomer.contactName"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field
                        label="Email"
                        outlined
                        :disabled="disabled"
                        v-model="localCustomer.contactMail"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                        label="Telefon"
                        outlined
                        :disabled="disabled"
                        v-model="localCustomer.contactPhone"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col offset="3" cols="6">
                        <FormDeleteButton 
                        v-if="!disabled && !newCustomer" 
                        verbose
                        :objectToDelete="localCustomer" 
                        :entity="$store.getters.getEntities.CUSTOMER"
                        />
                        <FormSaveButton
                        class="text-center"
                        verbose 
                        :objectToSave="localCustomer" 
                        :entity="$store.getters.getEntities.CUSTOMER"
                        :newObject="newCustomer"
                        v-if="!disabled"
                        :disabled="!valid"
                        goTo="Edit.CustomerView"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </main>
</template>

<script>
import FormSaveButton from '@/components/buttons/FormSaveButton.vue'
import FormDeleteButton from '@/components/buttons/FormDeleteButton.vue'
export default {
    components:{
        FormSaveButton,
        FormDeleteButton
    },
    data(){
        return {
            valid: true,
            title: '',
            localCustomer: {
                _id: '',
                name: '',
                address: '',
                location: '',
                zip: '',
                info: '',
                contactName: '',
                contactMail: '',
                contactPhone: '',
            },
            // Gets meta data from router if its supposed to create a blank form
            newCustomer: this.$router.currentRoute.matched.some(route => route.meta.new),
            // Gets meta data from router if its supposed to disable elements of the form
            disabled: this.$router.currentRoute.matched.some(route => route.meta.disabled),
            validation: {
                nameRules: [
                    name => !!name || 'Namen angeben!'
                ],
                mailRules: [
                    mail => /.+@.+\..+/.test(mail) || 'Keine valide Mailadresse.'
                ],
                notEmpty: [
                    input => (input.replace(/ /g,'').length > 0) || 'Bitte etwas eingeben!'
                ]
            }
        } 
    },
    methods: {
        /**
         * Setup customer form depending on meta data from router
         */
        setupCustomer: function(){
            if(!this.newCustomer){
                this.$store.dispatch('get', {endpoint: this.$store.getters.getEntities.CUSTOMER, _id: this.$route.params._id})
                    .then((customer)=>{
                        this.localCustomer = customer.data
                        this.title = this.getTitle()
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
            }else{
                this.title = this.getTitle()
            }
        },
        getTitle: function(){
            if(this.newCustomer) return 'Neuen Kunden erstellen'
            return `Kunde ${this.localCustomer.name} wird ${this.disabled ? 'angezeigt' : 'bearbeitet'}`
        }
    },
    computed: {
        loaded(){
            return this.$store.getters.watchLoading
        }
    },
    watch: {
        loaded(){
            // refresh form when a reload is indicated from vuex store
            this.setupCustomer()
        }
    },
    /**
     * Setup customer and form on mount
     */
    beforeMount(){
        this.setupCustomer()
    }
}
</script>

<style>

</style>