import Vuex from 'vuex'
import Vue from 'vue'
var axios = require('axios')
axios.defaults.withCredentials = true

/**
 * Settings for the global store
 * Enables a consistent state throughout all components
 */
Vue.use(Vuex)
const store = new Vuex.Store({
    /**
     * DO NOT CHANGE STATE MANUALLY, USE MUTATIONS BELOW
     * https://vuex.vuejs.org/guide/state.html
     */
    state: {
        /**
         * All necessaries for auth FROM CURRENT USER
         */
        auth: {
            isSignedIn: false,
            role: '',
            name: '',
            statusFailed: false
        },
        /**
         * Registered entities throughout the app NOT TO BE CHANGED DURING RUNTIME
         * If new data models are implemented in the future, add the entity here
         * NEEDS TO MAP TO THE CORRESPONDING REST API ENDPOINT
         */
        ENTITIES: {
            USER: 'user',
            ROLE: 'role',
            CUSTOMER: 'customer',
            DATAREF: 'dataref',
            HC: 'healthcheck',
            QUEST: 'question'
        },
        /**
         * Globally enables control over the snachbar to show (un)successfull loading requests, etc
         */
        snackbars: {
            // For further information see (@/components/snachbars/DefaultSnackbar.vue)
            defaultSnackbar: {
                show: false,
                timeout: 3000,
                success: true,
                text: ''
            },
            // register new snackbars here
        },
        /**
         * State to watch by components in case they need to know if they have to reload smth
         */
        loaded: false
    },
    /**
     * https://vuex.vuejs.org/guide/mutations.html
     */
    mutations: {
        // Set the auth data from currently logged in user on sign in
        setAuthData(state, auth){
            state.auth.isSignedIn = auth.isSignedIn
            state.auth.name = auth.name
            state.auth.role = auth.role
            state.auth.statusFailed = auth.statusFailed
        },
        /**
         * Activates snackbar with given parameters
         * @param {Object} snackbar success, text
         */
        showDefaultSnackbar(state, snackbar){
            var snack = state.snackbars.defaultSnackbar
            snack.success = snackbar.success
            snack.text = snackbar.text
            snack.show = true
            setTimeout(()=>{
                snack.show = false
            },snack.timeout)
        },
        /**
         * Toggles loading state and triggers watchers
         * @param {*} state 
         */
        toggleLoading(state){
            state.loaded = !state.loaded
        }
    },
    /**
     * Getters return the state of the defined variables (sometimes adjusted)
     * https://vuex.vuejs.org/guide/getters.html
     */
    getters: {
        /**
         * Returns base url depending on environment
         * @returns string
         */
        getBaseUrl: ()=>{
            if(process.env.NODE_ENV === 'production') return 'https://healthcheck.wg-data.de/api/'
            if(process.env.NODE_ENV === 'staging') return 'https://wgdata.healthcheck.devopsdavid.de/api/'
            return 'http://localhost:3000/api/'
        },
        /**
         * Returns the login state of the current user
         * @param {*} state vuex state
         * @returns login state
         */
        isLoggedIn(state){
            return state.auth.isSignedIn
        },
        /**
         * Get all entities currently allowed in the system
         * @param {*} state vuex state
         * @returns Object of allowed entities
         */
        getEntities(state){
            return state.ENTITIES
        },
        /**
         * Get reactive state of snackbar, used in DefaultSnackbar.vue
         */
        getDefaultSnackbar(state){
            return state.snackbars.defaultSnackbar
        },
        /**
         * Triggers watchers in case loading happend
         */
        watchLoading(state){
            return state.loaded
        },
        /**
         * Returns a globally unique id
         */
        getGUI(){
            return () => ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
        }
    },
    /**
     * https://vuex.vuejs.org/guide/actions.html
     */
    actions: {
        /**
         * Authenticates user against server
         * @param {*} param0 vuex methods
         * @param {*} creds usercredentials
         */
        async login({commit, getters}, creds){
            await axios.post(getters.getBaseUrl+'login', creds)
                .then((user)=>{
                    commit("setAuthData", {
                        isSignedIn: true,
                        name: user.data.name,
                        role: user.data.role,
                        statusFailed: false
                    })
                })
                .catch(()=>{
                    commit("setAuthData", {
                        isSignedIn: false,
                        name: "",
                        role: "",
                        statusFailed: true
                    })
                })
        },
        /**
         * Returns promise from GET request to server, serving ALL instances
         * @param {*} param0 vuex methods
         * @param {*} endpoint endpoint to get
         * @returns Promise
         */
        getAll({getters}, endpoint){
            return axios.get(getters.getBaseUrl+`${endpoint}`)   
        },
        /**
         * Returns promise from GET request to server, serving ONE instances by _id
         * @param {*} param0 vuex methods
         * @param {*} endpoint endpoint to get
         * @param {*} id id of object to retrieve
         * @returns Promise
         */
        get({getters}, {endpoint, _id}){
            return axios.get(getters.getBaseUrl+`${endpoint}/${_id}`)   
        },
        getByQuery({getters}, {endpoint, _id, params}){
            return _id ? axios.get(getters.getBaseUrl+`${endpoint}/${_id}`, {params}) : axios.get(getters.getBaseUrl+`${endpoint}`, {params: params}) 
        },
        constructHc({getters}, questions){
            return axios.post(getters.getBaseUrl+'dataref/questions', questions)
        },
        /**
         * Returns promise from PUT request to server
         * @param {*} param0 vuex methods
         * FOLLOWING PARAMS NEED TO BE PASSED AS AN OBJECT WITH THE SPECIFIED KEYS
         * @param {*} endpoint endpoint to get
         * @param {*} load load for update, requires the mongoose _id to find object on server
         * @returns Promise
         */
        put({getters}, {endpoint, load}){
            return axios.put(getters.getBaseUrl+`${endpoint}/${load._id}`, load)
        },
        /**
         * Returns promise from POST request to server
         * @param {*} param0 vuex methods
         *  FOLLOWING PARAMS NEED TO BE PASSED AS AN OBJECT WITH THE SPECIFIED KEYS
         * @param {*} endpoint endpoint to get
         * @param {*} load load for update
         * @returns Promise
         */
        post({getters}, {endpoint, load, params}){
            return axios.post(getters.getBaseUrl+`${endpoint}`, load, {params: params})
        },
        /**
         * Returns promise from DELETE request to server
         * @param {*} param0 vuex methods
         *  FOLLOWING PARAMS NEED TO BE PASSED AS AN OBJECT WITH THE SPECIFIED KEYS
         * @param {*} endpoint endpoint to get
         * @param {*} load load to get the _id from
         * @returns Promise
         */
        delete({getters}, {endpoint, load}){
            return axios.delete(getters.getBaseUrl+`${endpoint}/${load._id}`)
        },
        /**
         * Logout for current user
         * @param {*} param0 vuex methods
         */
        logout({getters}){
            return axios.post(getters.getBaseUrl+'logout')
        },
        /**
         * Download hc by _id
         * @param {*} param0 vuex methods
         */
        download({getters}, {endpoint, _id}){
            return axios({
                url: getters.getBaseUrl+`download/${endpoint}/${_id}`,
                responseType: 'arraybuffer',
                method: 'post'
            })
        },
        /**
         * Activates snackbar with given parameters
         * @param {Object} snackbar success, text
         */
         showDefaultSnackbar({commit}, snackbar){
            commit('showDefaultSnackbar', snackbar)
            if(snackbar.toggleLoading === undefined || snackbar.toggleLoading === true){
                commit('toggleLoading')
            }
        }, 
        createNewHcVersion({getters}, {id}){
            return axios.post(getters.getBaseUrl+`healthcheck/createNewVersion`, {healthcheckId: id})
        }
    }
})

export default store