<template>
    <main>
        <v-select
          :items="roles"
          item-text="name"
          item-value="name"
          label="Rolle"
          solo
          return-object
          @change="emitChange"
          :value="value ? value:roles[0]"
          :disabled="disabled"
        ></v-select>
    </main>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            roles: []
        }
    },
    methods: {
        emitChange: function(role){
            this.$emit('change', role)
        }
    },
    /**
     * Only load role data from server if mounted
     */
    beforeMount(){
        this.$store.dispatch('getAll', this.$store.getters.getEntities.ROLE)
            .then((roles)=>{
                this.roles = roles.data
                this.emitChange(this.roles[0])
            })
            .catch((err)=>{
                console.log(err)
            })
    }
}
</script>

<style>

</style>