<template>
    <main>
        <AddButtonCard
        title="Kunden"
        :entity="entity"
        titleToPass="Kunden hinzufügen"
        :viewName="viewName"
        >
            <v-simple-table slot="component">
                <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Unternehmen
                        </th>
                        <th class="text-left">
                            Ansicht
                        </th>
                        <th class="text-left">
                            Bearbeiten
                        </th>
                        <th class="text-left">
                            Healthcheck
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="customer in sortedCustomers"
                        :key="customer.name"
                        >
                        <td>{{ customer.name }}</td>
                        <td>
                            <FormShowButton :viewName="viewName" :params="{_id: customer._id}"/>
                        </td>
                        <td>
                            <FormEditButton :viewName="viewName" :params="{_id: customer._id}"/>
                        </td>
                        <td>
                            <FormCreateButton 
                            :viewName="'HealthcheckView'" 
                            :params="{_id: customer._id}"
                            :absolute="false"
                            :bottom="false"
                            :right="false"
                            :fab="false"
                            outlined
                            />
                        </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </AddButtonCard>
    </main>
</template>

<script>
import FormEditButton from '@/components/buttons/FormEditButton.vue'
import FormCreateButton from '@/components/buttons/FormCreateButton.vue'
import FormShowButton from '@/components/buttons/FormShowButton.vue'
import AddButtonCard from '@/components/cards/AddButtonCard.vue'

export default {
    components:{
        FormEditButton,
        FormCreateButton,
        FormShowButton,
        AddButtonCard
    },
    data(){
        return {
            viewName: 'CustomerView',
            entity: this.$store.getters.getEntities.CUSTOMER,
            customers: []
        }
    },
    methods: {
        getAllCustomers: function(){
            this.$store.dispatch('getAll', this.entity)
                .then((customers)=>{
                    this.customers = customers.data
                })
        }
    },
    computed: {
        sortedCustomers(){
            return this.customers.slice().sort((a,b)=>{
                if(a.name < b.name) return -1
                if(a.name > b.name) return 1
                return 0
            })
        },
        loaded(){
            return this.$store.getters.watchLoading
        }
    },
    watch: {
        loaded(){
            this.getAllCustomers()
        }
    },
    beforeMount(){
        this.getAllCustomers()
    }

}
</script>

<style>

</style>